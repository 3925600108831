import React, { useContext, useState } from "react";
import NewPropertyContext from "../../../Context/NewPropertyContext";
import { Checkbox, FormControlLabel, FormGroup, Divider } from "@mui/material";
import Trash from "../../../Assets/trash-2.svg";
import Add from "../../../Assets/add.svg";

import "./Step7.css";

const Automated = () => {
  const { step7Form } = useContext(NewPropertyContext);
  const [newItem, setNewItem] = useState([]);

  let newList = () => {
    if (newItem.url !== "" && newItem.name !== "") {
      step7Form.setFieldValue("iCalLinks", [...step7Form.values.iCalLinks, newItem]);
      setNewItem([]);
    } else {
      return alert("Enter the iCalLinks");
    }
  };

  const handleInput = (e, name) => {
    let input = e.target.value;

    setNewItem((prevState) => {
      return {...prevState, [name]: input};
    })
  }

  const handleChange = (type) => {
    if(type === "chekout") {
      step7Form.setFieldValue("cleaningOn", true);
    } else {
      step7Form.setFieldValue("cleaningOn", false);
    }
  };

  // console.log(newItem);

  const removeItem = (item) => {
    const list = step7Form.values.iCalLinks.filter((value) => value !== item);
    step7Form.setFieldValue("iCalLinks", list);
  };


  // console.log(step7Form.values);

  return (
    <>
      <div className="subtitleIcall">Add one or many iCall links</div>
      
      <div className="linkContainer">
        {step7Form.values.iCalLinks.length > 0 ? ( step7Form.values.iCalLinks.map((value, idx) => (
        <div key={idx}>
          <span>{`${value.name} • ${value.url}`}</span>
          <button onClick={() => removeItem(value)}>
            <img src={Trash} alt="trash" />
          </button>
        </div>
        )) ) : null}
      </div>
      <form className="containerAddTak">

        <div className="conatainerStep7Input">
          <input
            placeholder="iCall label"
            onChange={(event) => handleInput(event, "name")}
            className="input"
          />

          <input
            placeholder="iCall link"
            onChange={(event) => handleInput(event, "url")}
            className="input"
          />

          <input
            value="ADD"
            className="inputReset"
            type="reset"
            onClick={newList}
            style={{
              backgroundColor: "#7cc8e4",
              border: "none",
              color: "#fff",
              width: "10%",
              cursor: "pointer",
            }}
          />
        </div>

        {/* <button className="button" onClick={newList} type="reset">
          <img src={Add} alt="Add" />
          <span>NEW ITEM</span>
        </button> */}
      </form>

      <Divider style={{ marginTop: 18, marginBottom: 20,  maxWidth: 721 }}/>

      <FormGroup style={{ marginTop: 20, display: "flex", flexDirection: "row"}}>
        <FormControlLabel  
          control={
            <Checkbox
              checked={step7Form.values.cleaningOn}
              onChange={() => handleChange("chekout")}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Cleaning on checkout"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={!step7Form.values.cleaningOn}
              onChange={() => handleChange("chekin")}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Cleaning on checkin"
        />
      </FormGroup>
    </>
  );
};

export default Automated;