import React, { useState } from "react";
import { Input } from "../../Components/Input";
import { InputsContainer } from "../../Components/InputsContainer";
import { Checkbox, FormControlLabel, FormGroup, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import api from "../../Service/api";

import "./About.css";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const About = ({ form, onBlur, loading, id }) => {
  let navigate = useNavigate();

  const [checked, setChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const [reloadPage, setReloadPage] = useState(false)
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    form.setFieldValue("laundry", event.target.checked);
  };

  const deleteNotifications =  async () => {
    try {
      setReloadPage(true);

      await api.delete(`/addresses/${id}`);

      navigate("/Properties");
    } catch(error) {
     if (error?.response?.status) {
          if (error.response.status !== 401) {
            if (
              error?.response?.data?.errors &&
              error?.response?.data?.errors[0]?.title
            ) {
              alert(
                `${error.response.data.errors[0].title}`
              );
            } else {
              alert(
                "Something unexpected happened.",
                "Please try again."
              );
            }
          } else {
            alert("Session expired", "Please login in again.");
          }
        } else {
          alert("Something unexpected happened.", "Please try again.");
        }
        setReloadPage(false);
    }
  }

  return (
    <>
      <div style={{ maxWidth: "721px", marginTop: 40 }}>
        <InputsContainer>
          <Input
            placeholder="Property name"
            field="nickname"
            form={form}
            assistiveText={{ color: "#0E1F41" }}
          />

          <Input
            placeholder="Zipcode"
            field="zipCode"
            form={form}
            keyboardType="number"
            onBlur={onBlur}
          />

          <Input
            placeholder="Address"
            field="address"
            form={form}
            assistiveText={{ color: "#0E1F41" }}
          />

          <Input placeholder="State" field="state" form={form} />

          <Input
            placeholder="City"
            field="city"
            form={form}
            assistiveText={{ color: "#0E1F41" }}
          />
        </InputsContainer>

        <Divider style={{ marginTop: 0, marginBottom: 35, maxWidth: 721 }} />

        <InputsContainer>
          <Input
            placeholder="Number of rooms"
            field="bedrooms"
            form={form}
            keyboardType="number"
            assistiveText={{ color: "#0E1F41" }}
          />

          <Input
            placeholder="Number of bathrooms"
            field="bathrooms"
            form={form}
            keyboardType="number"
            assistiveText={{ color: "#0E1F41" }}
          />

          <Input
            placeholder="Square feet"
            field="squareFeet"
            form={form}
            keyboardType="number"
            assistiveText={{ color: "#0E1F41" }}
          />

          <Input
            placeholder="Other..."
            field="other"
            form={form}
            assistiveText={{ color: "#0E1F41" }}
          />
        </InputsContainer>

        <FormGroup style={{ marginTop: 20 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="I want the cleaner to do the laundry"
          />
        </FormGroup>

        <button onClick={form.submitForm} className="buttonNewProperty">
          {loading ? "SAVING..." : "SAVE"}
        </button>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete property
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Do you want to delete the property{" "}
              <strong style={{ textTransform: "uppercase" }}>
                {form.values.nickname}
              </strong>{" "}
              ?
            </Typography>

            <div className="containerButtonDelete">
              <button className="close" onClick={handleClose}>
                CLOSE
              </button>
              <button className="delete" onClick={() => deleteNotifications()}>
                {reloadPage ? "DELETING..." : "DELETE"}
              </button>
            </div>
          </Box>
        </Modal>

        <button
          onClick={() => setOpen(true)}
          className="buttonNewProperty"
          style={{
            marginBottom: 50,
            backgroundColor: "#b00020",
            border: "none",
          }}
        >
          DELETE
        </button>
      </div>
    </>
  );
};

export default About;
