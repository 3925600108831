import React, { useContext } from "react";
import {
  Button,
  Box,
  MenuItem,
  Menu,
  Fade,
  Avatar,
  ListItemIcon
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AppContext from "../../Context/AppContext";

import './MainTopBar.css';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  display: "flex",
  flexDirection: "row",
  borderRadius: "8px",
  backgroundColor: "#E5EBF2",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  height: "48px",
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '40%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#A9B2C2',
  fontSize: "15px",
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '350px',
    },
  },
}));


export const MainTopbar = () => {
  const { signOut, user } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let navigate = useNavigate();


  return (
    <Box className="containerTopBar">
      <Search>
        <StyledInputBase
          placeholder="Need Help?"
          inputProps={{ 'aria-label': 'search' }}
        />
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
      </Search>
      {/* <Button color="inherit" onClick={() => signOut()}>
        <Typography color={"black"} component="div" sx={{ flexGrow: 1 }}>
          Sair
        </Typography>
      </Button> */}
      <div className="containerRightTopBar">
        <Avatar alt={user.firstName} src="/static/images/avatar/1.jpg" />

        <div className="containerText">
          <span className="name">{user.firstName}</span>
          <span className="position">admin</span>
        </div>
        <div>
          <Button
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <ListItemIcon>
              <ExpandMoreIcon fontSize="large" />
            </ListItemIcon>
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem
              onClick={() =>
                navigate(`/Profile`, {
                  state: { editAccount: false },
                })
              } 
            >Profile Settings</MenuItem>
            <MenuItem
              onClick={() =>
                navigate(`/Profile`, {
                  state: { editAccount: true },
                })
              }
            >Account Settings</MenuItem>
            <MenuItem onClick={signOut}>Sign Out</MenuItem>
          </Menu>
        </div>
      </div>
    </Box>
  );
};
