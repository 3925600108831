import React from 'react';
// import ContainerGrid from '../../Components/ContainerGrid/ContainerGrid';
import { useNavigate } from "react-router-dom";
import { AuthorizedLayout } from '../../Components/AuthorizedLayout';
import { Button } from '../../Components/Button';
import SliderComponent from "./Components/SliderComponent";

import "./Components/PropertyCard.css";

const Properties = () => {
  let navigate = useNavigate();
  return (
    <AuthorizedLayout>
     <div style={{ display: "flex", justifyContent: "space-between" }}>
      <span>
        <p className='titlePage'>Properties</p>
        <p className='subtitlePage'>Here is all your properties</p>
      </span>
      <span>
        <Button text="New property" style={{ width: 199, height: 41 }} primary onClick={() => navigate("/step1")}/>
      </span>
     </div>
      <SliderComponent />
    </AuthorizedLayout>
  );
};

export default Properties;
