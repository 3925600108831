import React from "react";
import { Box } from "@mui/system";

import { MainTopbar } from "../MainTopbar";
import { MainSidebar } from "../MainSidebar";
import { ContentContainer } from "./ContentContainer";

export const AuthorizedLayout = ({ children }) => {
  return (
    <Box style={{ backgroundColor: "#e5ebf2", display: "flex" }}>
      <div style={{ flex: 1 }}>
        <MainSidebar/>
      </div>
      <div style={{ flex: 7 }}>
        <MainTopbar/>
        <ContentContainer>{children}</ContentContainer>
      </div>
    </Box>
  );
};
