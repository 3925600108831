import React from "react";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { SidebarLink } from "./styles";

import calendar from '../../Assets/calendar.svg';
import mail from '../../Assets/mail.svg';
import map from '../../Assets/map.svg';
import star from '../../Assets/star.svg';
import user from '../../Assets/user.svg';
import logo from '../../Assets/Group 807.svg';
import dolar from '../../Assets/dollar-sign.svg';

import './SideBar.css';

export const MainListItems = () => {
  return (
    <div className='containerListItens'>
      
      <ListItemButton>
        <ListItemIcon>
          <img alt='images' src={logo} />
        </ListItemIcon>
      </ListItemButton>
      
      <Toolbar />
      <SidebarLink to={"/Schedules"}>
        <ListItem button>
          <ListItemIcon>
            <img alt='images' src={calendar} />
          </ListItemIcon>
          <ListItemText primary="Schedules" />
        </ListItem>
      </SidebarLink>

      <SidebarLink to={"/Properties"}>
        <ListItem button>
          <ListItemIcon>
            <img alt='images' src={map} />
          </ListItemIcon>
          <ListItemText primary="Properties" />
        </ListItem>
      </SidebarLink>

      <SidebarLink to={"/Reviews"}>
        <ListItem button>
          <ListItemIcon>
            <img alt='images' src={star} />
          </ListItemIcon>
          <ListItemText primary="Reviews" />
        </ListItem>
      </SidebarLink>

      <SidebarLink to={"/Notifications"}>
        <ListItem button>
          <ListItemIcon>
            <img alt='images' src={mail} />
          </ListItemIcon>
          <ListItemText primary="Notifications" />
        </ListItem>
      </SidebarLink>

      <SidebarLink to={"/Profile"}>
        <ListItem button>
          <ListItemIcon>
            <img alt='images' src={user} />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItem>
      </SidebarLink>

      <SidebarLink to={"/Billings"}>
        <ListItem button>
          <ListItemIcon>
            <img alt='images' src={dolar} />
          </ListItemIcon>
          <ListItemText primary="Billings" />
        </ListItem>
      </SidebarLink>
    </div>
  );
};
