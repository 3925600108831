import React, { useState, useEffect, useContext } from "react";
import api from '../../Service/api';
import AppContext from "../../Context/AppContext";
import { AuthorizedLayout } from '../../Components/AuthorizedLayout';
import { useLocation } from "react-router-dom";
import { Divider } from '@mui/material';
import Edit from './Components/Edit';
import Payment from './Components/Payment';

import "./Components/Payment.css";

const Profile = () => {
  const { state } = useLocation();
  const [selected, setSelected] = useState(state?.editAccount === true ? false : true);


  return (
    <AuthorizedLayout>
      <div className="containerAllCRedCard">
        <p className="titlePage">Profile</p>
        <p className="subtitlePage" style={{ width: "320px" }}>Edit your info</p>
        <div className="conatainerButtons">
          <button
            className={
              selected ? "buttonReviews buttonReviewsSelected" : "buttonReviews"
            }
            onClick={() => setSelected(true)}
          >
            EDIT YOUR PROFILE
          </button>
          <button
            className={
              !selected ? "buttonReviews buttonReviewsSelected" : "buttonReviews"
            }
            onClick={() => setSelected(false)}
          >
            PAYMENT METHOD
          </button>
        </div>
        <Divider style={{ marginTop: 15, marginBottom: 15,  maxWidth: 721 }}/>
        {selected ? (
            <>
              <Edit />
            </>
          ) : (
            <>
              <Payment />
            </>
          )}
      </div>
    </AuthorizedLayout>
  );
};

export default Profile;
