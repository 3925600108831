import React, { useContext, useState, useEffect } from "react";
import NewPropertyContext from "../../../Context/NewPropertyContext";
import api from "../../../Service/api";
import { AuthorizedLayout } from "../../../Components/AuthorizedLayout";
import { User, Key, Calendar, DollarSign } from 'react-feather';

import { useNavigate } from 'react-router-dom';

import Automated from './Automated';
import Manual from './Manual';

import "./Step7.css";

const Step7 = () => {
  const { step1Form, step7Form, loading } = useContext(NewPropertyContext);
  const [selected, setSelected] = useState('AUTOMATED');
  const [loadingCost, setLoadingCost] = useState(true);
  const [price, setPrice] = useState("0");

  const navigate = useNavigate();

  const checkLinkIcal = () => {
    if (step7Form.values.iCalLinks.length <= 0 && selected === 'AUTOMATED') {
      return alert("Please add at least one ical link")
    } else {
      return step7Form.submitForm()
    }
  }

  useEffect(() => {
    getCost();
  }, []);

  const getCost = async () => {
    try {
      setLoadingCost(true);
      const { data } = await api.post("/addresses/price", {
        data: {
          attributes: {
            bedroomsAmount: step1Form?.values?.bedrooms,
            bathroomsAmount: step1Form?.values?.bathrooms,
          },
        },
      });

      if (data) {
        setPrice(data?.attributes?.price || "Unavailable");
      }
    } catch (error) {
      setPrice("Unavailable");
    } finally {
      setLoadingCost(false);
    }
  };

  const checkScheduleDates = () => {
    setSelected("SKIP")
    step7Form.setFieldValue("checkiSchedule", true);
  }

  const continueManual = () => {
    setSelected("MANUAL")
    step7Form.setFieldValue("checkiSchedule", false);
  }

  const continueAutomated= () => {
    setSelected("AUTOMATED")
    step7Form.setFieldValue("checkiSchedule", false);
  }

  return (
    <AuthorizedLayout>
      <div style={{ maxWidth: "721px" }} className="containerStep7">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>
            <p className="titlePage">New property</p>
            <p className="subtitlePageProperty">Schedule cleaning</p>
            <p className="subtitlePageProperty">
              Now it's time to schedule your first cleaning. Automate your
              bookings calendar through iCal, PMS or manually schedule your
              cleaning.
            </p>
          </span>
        </div>

        <div
          style={{
            // borderWidth: 1,
            // borderColor: "rgb(85,222,219)",
            marginBottom: 10,
            //backgroundColor: "#ffffff",
            display: "flex",
            borderRadius: 5,
            borderColor: "rgba(66, 66, 67, 1)",
            borderBottomWidth: 1,
          }}
        >
          <span
            style={{
              justifyContent: "center",
              alignContent: "center",
              textAlignVertical: "center",
              fontSize: 14,
              fontWeight: "700",
              color: "rgba(66, 66, 67, 1)",
            }}
          >{`Cleaning Cost:`}</span>
          {/* <DollarSign
            name="dollar-sign"
            size={16}
            color="#1D4E92"
            style={{ marginLeft: 5, textAlignVertical: "center" }}
          /> */}

          {loadingCost ? (
            <div></div>
          ) : (
            <span
              style={{
                justifyContent: "center",
                alignContent: "center",
                textAlignVertical: "center",
                fontSize: 14,
                fontWeight: "700",
                color: "rgba(66, 66, 67, 1)",
                marginLeft: 8,
              }}
            >
              {new Intl.NumberFormat("en-us", {
                style: "currency",
                currency: "USD",
              }).format(Number(price))}
            </span>
          )}
        </div>

        <div className="conatainerStep7Buttons">
          <button
            className={
              selected === "AUTOMATED"
                ? "buttonReviews buttonReviewsSelected"
                : "buttonReviews"
            }
            onClick={() => continueAutomated()}
          >
            AUTOMATED
          </button>
          <button
            className={
              selected === "MANUAL"
                ? "buttonReviews buttonReviewsSelected"
                : "buttonReviews"
            }
            onClick={() => continueManual()}
          >
            MANUAL
          </button>

          <button
            className={
              selected === "SKIP"
                ? "buttonReviews buttonReviewsSelected"
                : "buttonReviews"
            }
            onClick={() => checkScheduleDates()}
          >
            SKIP
          </button>
        </div>

        <div style={{ maxWidth: "721px" }}>
          {selected === "AUTOMATED" && <Automated />}
          {selected === "MANUAL" && <Manual price={price ? price : 0} />}
          {selected === "SKIP" && (
            <div className="subtitleIcall" style={{ marginTop: "40px" }}>
              Don't want to schedule a cleaning just yet? You can always do it
              later on.
            </div>
          )}
        </div>

        <button onClick={() => checkLinkIcal()} className="buttonNewProperty">
          {selected === "SKIP"
            ? loading
              ? "loading..."
              : "Skip and finish"
            : loading
            ? "loading..."
            : "Schedule Cleanings"}
        </button>
        <button
          onClick={() => navigate(-1)}
          className="buttonNewProperty"
          style={{ marginTop: "10px" }}
        >
          GO BACK
        </button>
      </div>
    </AuthorizedLayout>
  );
};

export default Step7;
