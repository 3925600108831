import React, { useState, useContext, useEffect } from "react";
import AppContext from "../../Context/AppContext";
import api from "../../Service/api";
import moment from "moment";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import PaymentCard from "./PaymentCard";
import "./Payment.css";
import GetPdf from "./components/GetPdf";
import LinearProgress from "@mui/material/LinearProgress";

const initialFilterState = {
  fromDate: null,
  toDate: null,
  property: null,
};

const Billings = () => {
  const { user, signOut } = useContext(AppContext);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [propertiesFIltered, setPropertiesFIltered] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState(initialFilterState);

  useEffect(() => {
    let filtered = payments?.filter((item) => {
      const itemDate = moment(item.schedule?.date).utc();
      const fromDate = moment(filter.fromDate?.$d).utc();
      const toDate = moment(filter.toDate?.$d).utc();
      const isWithinRange =
        (!filter.fromDate || itemDate.isSameOrAfter(fromDate, "day")) &&
        (!filter.toDate || itemDate.isSameOrBefore(toDate, "day"));
      
        const filteredProperty = item?.schedule?.address?.id === filter.property;
        const isFilteredNotProperty = (filter.property === 'All' || filter.property === null) && item;
  
      return (isFilteredNotProperty || filteredProperty) && isWithinRange;
    });

    setFilteredData(filtered);
  }, [payments, filter]);

  const handleFilterChange = (value, name) => {
    setFilter((prevState) => ({ ...prevState, [name]: value }));
  };

  const fetchPayments = () => {
    setLoading(true);
    setLoadingIcon(true);
    try {
      async function fetchData() {
        const { data: paymentsData } = await api.get("/payments");
        setPayments(paymentsData?.map((e) => ({ id: e.id, ...e.attributes })));

        const uniqueAddressesIds = [...new Set(paymentsData?.map((e) => e.attributes?.schedule?.address?.id))];

        setPropertiesFIltered(uniqueAddressesIds);
        setLoadingIcon(false);
        setLoading(false);
      }
      fetchData();
    } catch (error) {
      //console.log(_error);
      setLoadingIcon(false);
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(`${error.response.data.errors[0].title}`);
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPayments()
  }, [])

  const resetFil = () => {
    setFilter(initialFilterState)
  };

  return (
    <AuthorizedLayout>
      {loading ? (
        <div></div>
      ) : (
        <div className="containerPaymentCard">
          <p className="titlePage">Billing history</p>
          <div className="containerFilter">
            <div style={{ display: "flex", gap: 10, marginTop: 10 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Initial date"
                  value={filter.fromDate}
                  onChange={(newValue) => {
                    handleFilterChange(newValue, 'fromDate');
                  }}
                  name="fromDate"
                  className="inputTextField"
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Final date"
                  value={filter.toDate}
                  name="toDate"
                  disabled={filter.fromDate ? false : true}
                  onChange={(newValue) => {
                    handleFilterChange(newValue, 'toDate');
                  }}
                  className="inputTextField"
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <Box>
                <FormControl>
                  <Select
                    labelId="demo"
                    value={filter.property}
                    label="Name"
                    onChange={({ target }) => handleFilterChange(target.value, 'property')}
                    className="inputProperty"
                    sx={{
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                    displayEmpty
                  >
                    <MenuItem value="All">All properties</MenuItem>
                    {propertiesFIltered.map((value, idx) => (
                      <MenuItem
                        key={idx}
                        value={
                          value || "Deleted Property"
                        }
                      >{`${
                        value || "Deleted Property"
                      }`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <button onClick={() => resetFil()} className="resetFilter">
                Reset filter
              </button>
            </div>

            <button
              onClick={() => GetPdf(filteredData)}
              className="buttonPdf"
            >
              EXPORT
            </button>
          </div>

          {filteredData.length > 0 && (
            <div>
              {filteredData.map((item, idx) => (
                <div key={idx}>
                  <PaymentCard pay={item} key={idx} />
                  <div className="divider"></div>
                </div>
              ))}
            </div>
          )}

          {loadingIcon && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}

          {filteredData.length === 0 && !loadingIcon && !loading && (
            <div style={{ marginBottom: 50 }}>
              You don't have any payments yet
            </div>
          )}
        </div>
      )}
    </AuthorizedLayout>
  );
};

export default Billings;
