import React, { useContext, useState } from "react";
import NewPropertyContext from "../../Context/NewPropertyContext";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import Add from "../../Assets/add.svg";
import CardPhoto from './Components/CardPhoto';
import { useNavigate } from 'react-router-dom';

import "./NewProperty.css";

const Step3 = () => {
  const { step6Form } = useContext(NewPropertyContext);
  const [files, setFile] = useState();

  // console.log(step6Form.values);

  const navigate = useNavigate();

  const getBase64 = (file) => {
    return new Promise(resolve => {
      let baseURL = "";
      
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const handleFileInputChange = (e) => {
    
    const file = e.target.files[0];

    getBase64(file)
      .then(result => {
        file["base64"] = result;
        // console.log("File Is", file);
        step6Form.setFieldValue(`photos[${step6Form.values.photos.length}].data`, [
          // ...step6Form.values.photos,
          result,
        ]);
      })
      .catch(error => {
        if (error?.response?.status) {
          if (error.response.status !== 401) {
            if (
              error?.response?.data?.errors &&
              error?.response?.data?.errors[0]?.title
            ) {
              alert(
                `${error.response.data.errors[0].title}`
              );
            } else {
              alert("Something unexpected happened.", "Please try again.");
            }
          } else {
            alert("Session expired", "Please login in again.");
          }
        } else {
          alert("Something unexpected happened.", "Please try again.");
        }
      });
      setFile(e.target.files[0])
  };

  const deleteImage = async (imageIndex) => {
    const newSelectedImages = [...step6Form.values.photos];
    newSelectedImages.splice(imageIndex, 1);

    step6Form.setFieldValue("photos", newSelectedImages);
  }
  
  // console.log(step6Form.values);
  return (
    <AuthorizedLayout>
      <div style={{ maxWidth: "721px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>
            <p className="titlePage">New property</p>
            <p className="subtitlePageProperty">Photos of the clean place</p>
            <p className="subtitlePageProperty">
              Here you can access your last cleanings and help us keep the
              quality of the cleaning.
            </p>
          </span>
        </div>

        <label className="form-group button" htmlFor="fupload">
          <img src={Add} alt="Add" htmlFor="fupload" />
          <label htmlFor="fupload" className="control-label label-bordered">
            ADD IMAGES
          </label>
          <input
            type="file"
            id="fupload"
            name="fupload"
            className="fupload form-control"
            accept=".jpeg, .png, .jpg"
            onChange={handleFileInputChange}
          />
        </label>

        <div className="containerAllCardsPhotos">
          {step6Form.values.photos.length > 0
            ? step6Form.values.photos.map((value, idx) => (
                <CardPhoto
                  photo={value.data}
                  onClick={() => deleteImage(idx)}
                  form={step6Form}
                  id={idx}
                  key={idx}
                />
              ))
            : null}
        </div>

        <button onClick={step6Form.submitForm} className="buttonNewProperty">
          NEXT
        </button>
        <button onClick={() => navigate(-1)} className="buttonNewProperty" style={{ marginTop: '10px'}}>
          GO BACK
        </button>
      </div>
    </AuthorizedLayout>
  );
};

export default Step3;
