import React, { useContext, useEffect, useState } from "react";
import NewPropertyContext from "../../../Context/NewPropertyContext";
import TextField from "@mui/material/TextField";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import api from "../../../Service/api";
import * as yup from "yup";
import { useFormik } from "formik";
// import Trash from "../../../Assets/trash-2.svg";
// import Add from "../../../Assets/add.svg";

import "./Step7.css";
import { MobileDateTimePicker } from "@mui/x-date-pickers";

const Manual = () => {
  const { step7Form, step1Form } = useContext(NewPropertyContext);
  const [loadingCoupon, setLoadingCoupon] = useState(false);
  const [loadingCost, setLoadingCost] = useState(true);
  const [realPrice, setRealPrice] = useState(0);
  const [price, setPrice] = useState("0");
  const [checked, setChecked] = useState(false);

  const getCost = async () => {
    try {
      setLoadingCost(true);
      const { data } = await api.post("/addresses/price", {
        data: {
          attributes: {
            bedroomsAmount: step1Form?.values?.bedrooms,
            bathroomsAmount: step1Form?.values?.bathrooms,
          },
        },
      });

      if (data) {
        setPrice(`${data?.attributes?.price} USD` || "Unavailable");
        setRealPrice(data?.attributes?.price || 0);
      }
    } catch (error) {
      setPrice("Unavailable");
      setRealPrice(0);
    } finally {
      setLoadingCost(false);
    }
  };

  useEffect(() => {
    getCost();
  }, []);

  const validateCoupon = async () => {
    if (step7Form.values.code) {
      setLoadingCoupon(true);
      try {
        if (step7Form.values.code === "HELLOLA") {
          throw Error("Coupon not available on property registering.");
        } else {
          const { data } = await api.get(
            `/validate_coupon/${step7Form.values.code}`
          );
          if (data) {
            // console.log(data);
            step7Form.setFieldValue("id", data.id);
            step7Form.setFieldValue(
              "percent",
              data.attributes.discountPercentage
            );
            step7Form.setFieldValue("amount", data.attributes.discountPrice);
            step7Form.setFieldValue(
              "expirationDate",
              data.attributes.dateLimit
            );
            step7Form.setFieldValue(
              "errorText",
              step7Form.initialValues.errorText
            );
          }
          alert("Coupon added successfully");
        }
      } catch (error) {
        step7Form.setFieldValue("id", step7Form.initialValues.id);
        step7Form.setFieldValue("percent", step7Form.initialValues.percent);
        step7Form.setFieldValue("amount", step7Form.initialValues.amount);
        step7Form.setFieldValue(
          "expirationDate",
          step7Form.initialValues.expirationDate
        );
        step7Form.setFieldValue(
          "errorText",
          error?.message === "Coupon not available on property registering."
            ? "Coupon not available on property registering."
            : error?.response?.data?.error || "Unknown Error"
        );
        console.log(error?.response?.data?.error);
        alert(
          error?.message === "Coupon not available on property registering."
            ? "Coupon not available on property registering."
            : error?.response?.data?.error || "Unknown Error"
        );
      } finally {
        setLoadingCoupon(false);
      }
    }
  };

  // console.log(price);
  return (
    <>
      <form className="containerAddTak">
        <div className="conatainerStep7Input">
          {/* <input
            placeholder="iCall link title"
            onChange={(event) => handleInput(event, "title")}
            className="input"
          /> */}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="containerDateLabel">
              <div className="titleLabel">Checkout at</div>
              <MobileDateTimePicker
                className="buttonDate"
                // label="Checkout at"
                // disabled={checked}
                value={step7Form.values.checkoutDate}
                onChange={(newValue) => {
                  step7Form.setFieldValue("checkoutDate", newValue["$d"]);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>

            <div className="containerDateLabel">
              <div className="titleLabel">Checkin at</div>
              <MobileDateTimePicker
                className="buttonDate"
                // disabled={checked}
                // label="Checkin at"
                value={step7Form.values.checkinDate}
                onChange={(newValue) => {
                  step7Form.setFieldValue("checkinDate", newValue["$d"]);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </LocalizationProvider>

          {/* <button className="button" type="button">Checkout at</button>

          <button className="button" type="button">Checkin at</button> */}

          {/* <input
            placeholder="iCall link"
            onChange={(event) => handleInput(event, "link")}
            className="input"
          /> */}
        </div>
      </form>

      <div className="containerDateLabel" style={{ width: '100%', margin: '10px'}}>
        <div className="titleLabel">
          Please note that the cleaning schedule is based on the local time zone
          of the property.
        </div>
      </div>

      <div className="containerCoupon">
        <div className="containerSendCoupon">
          <form>
            <input
              placeholder="COUPON CODE"
              onChange={(event) =>
                step7Form.setFieldValue("code", event.target.value)
              }
              className="input"
            />

            <button type="reset" onClick={() => validateCoupon()}>
              {loadingCoupon ? "LOADING.." : "APPLY"}
            </button>
          </form>
        </div>

        <div className="titleCost">
          <div>Initial Cost</div>
          <div>Discount</div>
          <div>Final Cost</div>
        </div>

        <div className="dividerCoupon"></div>

        <div className="costValue">
          <div>{`$ ${Number(realPrice).toFixed(2)}` || "Unavailable"}</div>
          <div>
            {step7Form?.values?.percent
              ? `${step7Form?.values?.percent} %`
              : `$ ${Number(step7Form?.values?.amount).toFixed(2)}`}
          </div>
          <div>
            {`$ ${Number(
              step7Form?.values?.percent
                ? realPrice - (realPrice * step7Form?.values?.percent) / 100
                : realPrice - step7Form?.values?.amount
            ).toFixed(2)}`}
          </div>
        </div>
      </div>
    </>
  );
};

export default Manual;
