import React, { useState, useEffect, useContext } from "react";
import api from "../../Service/api";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import { Divider } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import placeholder from "../../Assets/placeholder.png";
import EditCoverImage from "./EditCoverImage";
import Schedules from "./Schedules";
import GetIn from "./GetIn";
import Tasks from "./Tasks";
import Consumables from "./Consumables";
import About from "./About";
import Ical from "./Ical";
import { useFormik } from "formik";
import * as yup from "yup";

import "./EditProperty.css";

const EditProperty = () => {
  const { id } = useParams();
  const {
    state: { property, editPhoto, createSchedule, iCal, allProperty },
  } = useLocation();
  const [selected, setSelected] = useState(
    iCal ? "Ical" : editPhoto ? "Schedules" : "About"
  );
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [activeScreen, setActiveScreen] = useState("NEED");
  const [zipCodeHasChanged, setZipCodeHasChanged] = useState(false);

  let navigate = useNavigate();

  const textMenu = [
    "Schedules",
    "Get in",
    "About",
    "Ical",
    "Tasks",
    "Consumables",
  ];

  const editProperty = async (values) => {
    const parsedForm = {
      data: {
        attributes: {
          addressNickname: values.nickname,
          address: values.address,
          zipCode: values.zipCode,
          city: values.city,
          state: values.state,
          getInAttributes: {
            id: property.getIn?.id || "",
            title: values.way,
            description:
              values?.way === "someone_is_home"
                ? "someone_is_home"
                : values.instructions || "someone_is_home",
          },
          cleaningAttributes: {
            id: property.cleaningId,
            bedroomsAmount: values.bedrooms,
            bathroomsAmount: values.bathrooms,
            squareFeet: values.squareFeet,
            laundry: values.laundry,
            cleaningOn: values.cleaningOn ? "check_out" : "check_in",
            consumablesAttributes: values.consumablesChecklist.map((i) => {
              if (i?.description) {
                return i;
              } else {
                return { description: i };
              }
            }),
          },
          IcalendarUrlsAttributes: values.icalendarUrls.map((i) => {
            if (i?.id) {
              return i;
            } else {
              return {
                name: i.name,
                url: i.url,
              };
            }
          }),
          addressChecklistsAttributes: values.tasksChecklist.map((i) => {
            if (i?.description) {
              return i;
            } else {
              return { description: i };
            }
          }),
        },
      },
    };

    try {
      setLoading(true);

      await api.put(`/addresses/${property.id}`, parsedForm);

      // navigate("/Properties");

      alert("Data updated successfully");
      setLoading(false);
    } catch (error) {
      // console.log(error);
      if (
        error?.message ===
        "Unfortunately we aren't available in your area today."
      ) {
        alert(
          "Unfortunately we aren't available in your area today.",
          "As soon as we start working in your location, we will inform you via the registered email."
        );
      } else {
        if (error?.response?.status) {
          if (error.response.status !== 401) {
            if (
              error?.response?.data?.errors &&
              error?.response?.data?.errors[0]?.title
            ) {
              alert(`${error.response.data.errors[0].title}`);
            } else {
              alert("Something unexpected happened.", "Please try again.");
            }
          } else {
            alert("Session expired", "Please login in again.");
          }
        } else {
          alert("Something unexpected happened.", "Please try again.");
        }
      }
      setLoading(false);
    }
  };

  const form = useFormik({
    initialValues: {
      icalendarUrls: property?.icalendarUrls || {},
      nickname: property?.addressNickname || "",
      address: property?.address || "",
      zipCode: property?.zipCode || "",
      state: property?.state || "",
      city: property?.city || "",
      way: property?.getIn?.title || "someone_is_home",
      instructions: property?.getIn?.description
        ? property?.getIn?.description === "someone_is_home"
          ? ""
          : property?.getIn?.description
        : "",
      bedrooms: property?.cleaning?.bedroomsAmount.toString() || "",
      bathrooms: property?.cleaning?.bathroomsAmount.toString() || "",
      squareFeet: property?.cleaning?.squareFeet?.toString() || "",
      laundry: property?.cleaning?.laundry || "",
      cleaningOn: property?.cleaning?.cleaningOn === "check_out" ? true : false,
      other: "",
      tasksChecklist: property?.addressChecklists || [],
      consumablesChecklist: property?.cleaning?.consumables || [],
    },
    validationSchema: yup.object({
      nickname: yup.string().required(),
      address: yup.string().min(5).required(),
      zipCode: yup.string().min(2).required(),
      state: yup.string().min(2).required(),
      city: yup.string().required(),
      bedrooms: yup.number().integer().min(0).required(),
      bathrooms: yup.number().positive().required(),
      // squareFeet: yup.number().positive().required(),
    }),
    validate: (values) => {
      const errors = {};

      if (values.way === "doorcode" && values.instructions === "") {
        errors.instructions = "Please specify the doorcode";
      }

      if (values.way === "hidden_key" && values.instructions === "") {
        errors.instructions = "Please specify where is the key";
      }

      if (values.way === "other" && values.instructions === "") {
        errors.instructions = "Please specify how to get in";
      }

      return errors;
    },
    onSubmit: (values) => editProperty(values),
  });

  // const verifyZipCode = async () => {
  //   if (form.values.zipCode.length === 5) {
  //     const { city, state } = await useZipCodeAPI(form.values.zipCode);
  //     if (city && state) {
  //       form.setFieldValue("city", city);
  //       form.setFieldValue("state", state);
  //     } else {
  //       setEditable(true);
  //     }
  //     setZipCodeHasChanged(true);
  //   }
  // };

  const verifyAddress = async () => {
    try {
      await api.post("/addresses/check_city_availability", {
        data: {
          attributes: {
            nickname: form.values.nickname,
            address: form.values.address,
            zipCode: form.values.zipCode,
            city: form.values.city,
            state: form.values.state,
          },
        },
      });
    } catch (error) {
      //console.log(error);
      throw Error("Unfortunately we aren't available in your area today.");
    }
  };

  const renderItemMenu = (name) => {
    switch (name) {
      case "Ical":
        return <Ical form={form} loading={loading} property={property} />;
      case "Schedules":
        return (
          <Schedules
            form={form}
            onClick={form.submitForm}
            id={id}
            property={property}
            createSchedule={createSchedule}
          />
        );
      case "Get in":
        return (
          <GetIn form={form} onClick={form.submitForm} loading={loading} />
        );
      case "About":
        return (
          <About form={form} onBlur={verifyAddress} loading={loading} id={id} />
        );
      case "Tasks":
        return (
          <Tasks form={form} onClick={form.submitForm} loading={loading} addresses={allProperty} />
        );
      case "Consumables":
        return (
          <Consumables
            form={form}
            onClick={form.submitForm}
            loading={loading}
            addresses={allProperty} 
          />
        );
      default:
        return <EditCoverImage property={property} />;
    }
  };

  // console.log(allProperty);

  return (
    <AuthorizedLayout>
      <div className="containerEditProperty">
        <div
          className="containerBackgroundProperty"
          style={{
            backgroundImage: `url(${
              property?.propertyImage?.url || placeholder
            })`,
          }}
        >
          <div>
            <div className="title">{property?.addressNickname || ""}</div>
            <div className="adress">{property?.address || ""}</div>
          </div>
          <button onClick={() => setSelected("")}>CHANGE IMAGES</button>
        </div>
        <div className="conatainerButtons">
          {textMenu.map((value, index) => (
            <button
              className={
                selected === value
                  ? "buttonNotSelected buttonSelected"
                  : "buttonNotSelected"
              }
              onClick={() => setSelected(value)}
              key={index}
            >
              {value}
            </button>
          ))}
        </div>
        {/* <Divider style={{ marginTop: 15, marginBottom: 15,  maxWidth: 650 }}/> */}
        {renderItemMenu(selected)}
      </div>
    </AuthorizedLayout>
  );
};

export default EditProperty;
