import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Screen1 from "./screen/Screen1";
import Screen2 from "./screen/Screen2";
import Screen3 from "./screen/Screen3";
import Screen4 from "./screen/Screen4";
import Screen5 from "./screen/Screen5";
import Screen6 from "./screen/Screen6";

import union1 from "../../../../Assets/Union (1).svg";
import union2 from "../../../../Assets/Union.svg";
import logo from "../../../../Assets/Sett Logo (1).svg";

import "./Onboard.css";
import { Backdrop, Fade, Modal } from "@mui/material";

const useStyles = makeStyles({
  cardContainer: {
    position: "relative",
  },
  dotContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
  },
  dot: {
    height: "16px",
    width: "16px",
    borderRadius: "50%",
    backgroundColor: "#FFFFFF",
    border: "1.4px solid #0DC9FF",
    marginRight: "8px",
    cursor: "pointer",
  },
  activeDot: {
    backgroundColor: "#0DC9FF",
  },
});

const Onboard = ({ setReviwed, open }) => {
  const classes = useStyles();
  const [cardIndex, setCardIndex] = useState(0);

  const selectScrenn = (index) => {
    switch (index) {
      case 0:
        return <Screen1 />;
      case 1:
        return <Screen3 />;
      case 2:
        return <Screen4 />;
      case 3:
        return <Screen5 />;
      default:
        return <Screen6 />;
    }
  };

  const handleNextClick = () => {
    if (cardIndex < 6 - 1) {
      setCardIndex(cardIndex + 1);
    }
  };

  const handlePreviousClick = () => {
    if (cardIndex < 6 - 1) {
      setCardIndex(cardIndex - 1);
    }
  };

  const handleDotClick = (index) => {
    setCardIndex(index);
  };

  const handleClose = () => {
    setReviwed(false);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="containerOnboard">
            <img src={union1} alt="img" className="img1" />
            <img src={union2} alt="img" className="img2" />
            <img src={logo} alt="img" className="img3" />
            <div className="backgroudCollO">
              <div className="cardContainer">
                {selectScrenn(cardIndex)}
                <div className="buttonOnborad">
                  <div className={classes.dotContainer}>
                    {[0, 0, 0, 0, 0].map((_, index) => (
                      <div
                        key={index}
                        className={`${classes.dot} ${
                          cardIndex === index ? classes.activeDot : ""
                        }`}
                        onClick={() => handleDotClick(index)}
                      />
                    ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "70%",
                      gap: "60px",
                    }}
                  >
                    {cardIndex > 0 && cardIndex < 5 && (
                      <button
                        onClick={handlePreviousClick}
                        className="buttonPrevious"
                      >
                        previous
                      </button>
                    )}

                    {cardIndex !== 4 && (
                      <button onClick={handleNextClick} className="buttonNext">
                        next
                      </button>
                    )}

                    {cardIndex === 4 && (
                      <button
                        className="buttonNext"
                        onClick={() => setReviwed(false)}
                      >
                        got it!
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default Onboard;
