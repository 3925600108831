import React, { useState } from "react";
import IconGOogle from "../../../Assets/Icon Google.svg";
import { useGoogleLogin } from "@react-oauth/google";
import { useParams } from "react-router-dom";

const Frist = () => {
  const { id } = useParams();
  const [codeToken, setCodeToken] = useState(null);

  const openApp = async (code) => {
    setCodeToken(code.code);
    
    const appUrl = id === "id=true"
      ? `SettcorpCleaner://id=${code.code}`
      : `SettcorpHost://id=${code.code}`;
  
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.includes("android");
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
  
    try {
      if (isAndroid) {
        const appPackageName = id === "true" ? "com.humanoide.sett" : "com.humanoide.setthost";
        const intentUri = `intent://${appPackageName}#Intent;scheme=${appUrl};end`;
        window.location.href = intentUri;
      } else if (isIOS) {
        window.location.href = appUrl;
      } else {
        throw new Error("This device is not supported.");
      }
    } catch (error) {
      console.error("Error while opening the app:", error);
      // You can handle the error here, e.g., display a message to the user.
    }
  };
  

  // const returnApp = async (code) => {
  //   setCodeToken(code.code);
  //   const appUrl = id === "id=true" ? `SettcorpCleaner://` : `SettcorpHost://`;
  //   window.location.href = appUrl;
  // };

  const returnApp = async (code) => {
    setCodeToken(code.code);

    const appUrl = id === "id=true" ? `SettcorpCleaner://` : `SettcorpHost://`;
  
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.includes("android");
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
  
    try {
      if (isAndroid) {
        const appPackageName = id === "true" ? "com.humanoide.sett" : "com.humanoide.setthost";
        const intentUri = `intent://${appPackageName}#Intent;scheme=${appUrl};end`;
        window.location.href = intentUri;
      } else if (isIOS) {
        window.location.href = appUrl;
      } else {
        throw new Error("This device is not supported.");
      }
    } catch (error) {
      console.error("Error while opening the app:", error);
      // You can handle the error here, e.g., display a message to the user.
    }
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => openApp(codeResponse),
    flow: "auth-code",
  });

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {!codeToken && (
        <button className="google-button" onClick={loginGoogle}>
          <img src={IconGOogle} alt="Add" className="google-logo" />
          Continue with Google
        </button>
      )}

      <div className="containerLines">
        <div className="line"></div>
        <div className="text">or return to app</div>
        <div className="line"></div>
      </div>

      <button className="return-button" onClick={returnApp}>
        return
      </button>
    </div>
  );
};

export default Frist;
