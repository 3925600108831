import React, { useContext, useState } from "react";
import NewPropertyContext from "../../Context/NewPropertyContext";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import { Input } from "../../Components/Input";
import { InputsContainer } from "../../Components/InputsContainer";
import { UseZipCodeAPI } from "../../Service/api";
import api from "../../Service/api";
import { useNavigate } from 'react-router-dom';

import "./NewProperty.css";

const Step2 = () => {
  const { step2Form } = useContext(NewPropertyContext);
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();


  const verifyZipCode = async () => {
    if (step2Form.values.zipCode.toString().length === 5) {
      const { city, state } = await UseZipCodeAPI(step2Form.values.zipCode.toString());

      if (city && state) {
        step2Form.setFieldValue("city", city);
        step2Form.setFieldValue("state", state);
      } else {
        setEditable(true);
      }
    }
    setEditable(true);
  };

  // console.log(step2Form.values.zipCode.toString());

  const verifyAddress = async () => {
    setLoading(true);
    try {
      await api.post("/addresses/check_city_availability", {
        data: {
          attributes: {
            addressNickname: step2Form.values.nickname.toString(),
            address: step2Form.values.address.toString(),
            zipCode: step2Form.values.zipCode.toString(),
            city: step2Form.values.city.toString(),
            state: step2Form.values.state.toString(),
          },
        },
      });
      step2Form.submitForm();
      setLoading(false);
    } catch (error) {
      // console.log(error);
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.error
          ) {
            alert(
              `${error.response.data.error}`
            );
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
    }
    setLoading(false);
  };

  return (
    <AuthorizedLayout>
      <div style={{ maxWidth: "721px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>
            <p className="titlePage">New property</p>
            <p className="subtitlePageProperty">Add property details</p>
          </span>
        </div>
        <InputsContainer>
          <Input
            placeholder="Property name"
            field="nickname"
            form={step2Form}
            assistiveText={{ color: "#0E1F41" }}
          />

          <Input
            placeholder="Zipcode"
            field="zipCode"
            form={step2Form}
            keyboardType="number"
            onBlur={verifyZipCode}
          />

          <Input
            placeholder="Address"
            field="address"
            form={step2Form}
            assistiveText={{ color: "#0E1F41" }}
          />

          <Input
            placeholder="State"
            field="state"
            form={step2Form}
            editable={editable}
          />

          <Input
            placeholder="City"
            field="city"
            form={step2Form}
            editable={editable}
            assistiveText={{ color: "#0E1F41" }}
          />
        </InputsContainer>

        <button onClick={verifyAddress} className="buttonNewProperty">
          {loading ? "LOADING..." : "NEXT"}
        </button>
        <button onClick={() => navigate(-1)} className="buttonNewProperty" style={{ marginTop: '10px'}}>
          GO BACK
        </button>
      </div>
    </AuthorizedLayout>
  );
};

export default Step2;
