import React, { useContext, useEffect, useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PropertyCard from './PropertyCard';
import LoadingCards from './LoadingCards';
import api from '../../../Service/api';
import './SliderComponent.css';
import AppContext from '../../../Context/AppContext';

import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  display: "flex",
  flexDirection: "row",
  borderRadius: "8px",
  backgroundColor: "white",
  marginLeft: 0,
  marginBottom: 15,
  width: '100%',
  height: "48px",
  [theme.breakpoints.up('sm')]: {
    width: '400px',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#A9B2C2',
  fontSize: "15px",
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '350px',
    },
  },
}));

const responsive = {
  desktop3: {
    breakpoint: { min: 2600 },
    items: 5,
    slidesToSlide: 6 // optional, default to 1.
  },
  desktop2: {
    breakpoint: { max: 2600, min: 2050 },
    items: 4,
    slidesToSlide: 5 // optional, default to 1.
  },
  desktop1: {
    breakpoint: { max: 2050, min: 1550 },
    items: 3,
    slidesToSlide: 4 // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 1550, min: 1024 },
    items: 2,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const SliderComponent = () => {
  const { loadingCoverPhoto, setLoadingCoverPhoto, setTotalProperties } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const [addressesSearch, setAddressesSearch] = useState([]);

  useEffect(() => {
    const fetchAddresses = async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/addresses");

        const parsedData = data.map((i) => ({
          id: i.id,
          ...i.attributes,
        }));
        setAddresses(parsedData);
        setTotalProperties(parsedData.length)
        setAddressesSearch(parsedData);
      } catch (error) {
        //console.log(_error);
        if (error?.response?.status) {
          if (error.response.status !== 401) {
            if (
              error?.response?.data?.errors &&
              error?.response?.data?.errors[0]?.title
            ) {
              alert(`${error.response.data.errors[0].title}`);
            } else {
              alert("Something unexpected happened.", "Please try again.");
            }
          } else {
            alert("Session expired", "Please login in again.");
          }
        } else {
          alert("Something unexpected happened.", "Please try again.");
        }
      }
      setLoading(false);
      setLoadingCoverPhoto(false);
    };
    fetchAddresses();
  }, [loadingCoverPhoto, setLoadingCoverPhoto]);

  const searchProperty = (property) => {
    const filtered = addresses.filter((value) =>
      value.addressNickname.toUpperCase().includes(property.toUpperCase())
    );
    setAddressesSearch(filtered);
  };

  // console.log(addresses);

  return (
    <div>
      <Search onChange={(event) => searchProperty(event.target.value)}>
        <StyledInputBase
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
        />
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
      </Search>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={false} // means to render carousel on server-side.
        infinite={true}
        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
        // autoPlaySpeed={1000}
        keyBoardControl={false}
        // customTransition="all .5"
        // transitionDuration={500}
        containerClass="carousel-container"
        // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        // deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {!loading
          ? addressesSearch.map((value, idx) => (
              <span key={idx}>
                <PropertyCard property={value} loading={loading} allProperty={addresses}/>
              </span>
            ))
          : [1, 2, 3, 4, 5].map((s, i) => <LoadingCards key={i} />)}
      </Carousel>
    </div>
  );
};

export default SliderComponent;
