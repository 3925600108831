import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import api from "../../../Service/api";
import moment from "moment";
import * as yup from "yup";
import { useFormik } from "formik";
// import Trash from "../../../Assets/trash-2.svg";
// import Add from "../../../Assets/add.svg";

import "./ManualSchedule.css";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";

const Manual = ({ goBack, price, id, property }) => {
  const [loadingCoupon, setLoadingCoupon] = useState(false);
  const [loadingCost, setLoadingCost] = useState(true);
  const [loading, setLoading] = useState(false);
  const [realPrice, setRealPrice] = useState(price);
  const navigate = useNavigate();

  const form = useFormik({
    initialValues: {
      id: null,
      code: "",
      percent: 0,
      amount: 0,
      expirationDate: "",
      errorText: "",
      checkoutDate: new Date(
        new Date(new Date(new Date().setHours(11)).setMinutes(0)).setSeconds(0)
      ),
      checkinDate: new Date(
        new Date(new Date(new Date().setHours(16)).setMinutes(0)).setSeconds(0)
      ),
    },

    onSubmit: (values) => createSchedule(values),
  });

  const createSchedule = async (values) => {
    try {
      setLoading(true);
      await api.post("schedules", {
        data: {
          attributes: {
            //date: values.checkoutDate,
            date: `${moment(values.checkoutDate).format("YYYY-MM-DD")}`,
            //time: values.checkoutDate,
            time: `${moment(values.checkoutDate).format("HH:mm")}`,
            //nextCheckinDate: values.checkinDate,
            nextCheckinDate: `${moment(values.checkinDate).format(
              "YYYY-MM-DD"
            )}`,
            //nextCheckinTime: values.checkinDate,
            nextCheckinTime: `${moment(values.checkinDate).format("HH:mm")}`,

            cleaningId: id,
            cleaningCouponId: values?.id || null,
          },
        },
      });

      alert("Cleaning scheduled successfully!");
      goBack();
    } catch (error) {
      console.log(error);
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(
              "An error has occurred." +
                ` ${error.response.data.errors[0].title}`
            );
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
      setLoading(false);
    }
  };

  const validateCoupon = async () => {
    if (form.values.code) {
      setLoadingCoupon(true);
      try {
        const { data } = await api.get(
          `/validate_coupon/${form.values.code}?address=${property.id}`
        );
        if (data) {
          // console.log(data);
          form.setFieldValue("id", data.id);
          form.setFieldValue("percent", data.attributes.discountPercentage);
          form.setFieldValue("amount", data.attributes.discountPrice);
          form.setFieldValue("expirationDate", data.attributes.dateLimit);
          form.setFieldValue("errorText", form.initialValues.errorText);
        }
        alert("Coupon added successfully");
      } catch (error) {
        form.setFieldValue("id", form.initialValues.id);
        form.setFieldValue("percent", form.initialValues.percent);
        form.setFieldValue("amount", form.initialValues.amount);
        form.setFieldValue("expirationDate", form.initialValues.expirationDate);
        form.setFieldValue(
          "errorText",
          error?.response?.data?.error || "Unknown Error"
        );
        console.log(error?.response?.data?.error);
        alert(error?.response?.data?.error || "Unknown Error");
      } finally {
        setLoadingCoupon(false);
      }
    }
  };

  // console.log(price);
  return (
    <div style={{ maxWidth: "721px" }}>
      <button className="goBackButton" onClick={() => goBack()}>
        {"<"}
      </button>

      <form className="containerAddTak">
        <div className="conatainerStep7Input">
          {/* <input
            placeholder="iCall link title"
            onChange={(event) => handleInput(event, "title")}
            className="input"
          /> */}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="containerDateLabel">
              <div className="titleLabel">Checkout at</div>
              <MobileDateTimePicker
                className="buttonDate"
                // label="Checkout at"
                value={form.values.checkoutDate}
                onChange={(newValue) => {
                  form.setFieldValue("checkoutDate", newValue["$d"]);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>

            <div className="containerDateLabel">
              <div className="titleLabel">Checkin at</div>
              <MobileDateTimePicker
                className="buttonDate"
                // label="Checkin at"
                value={form.values.checkinDate}
                onChange={(newValue) => {
                  form.setFieldValue("checkinDate", newValue["$d"]);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </LocalizationProvider>

          {/* <button className="button" type="button">Checkout at</button>

          <button className="button" type="button">Checkin at</button> */}

          {/* <input
            placeholder="iCall link"
            onChange={(event) => handleInput(event, "link")}
            className="input"
          /> */}
        </div>
      </form>

      <div className="containerDateLabel" style={{ width: '100%', margin: '10px'}}>
        <div className="titleLabel">
          Please note that the cleaning schedule is based on the local time zone
          of the property.
        </div>
      </div>

      <div className="containerCoupon">
        <div className="containerSendCoupon">
          <form>
            <input
              placeholder="COUPON CODE"
              onChange={(event) =>
                form.setFieldValue("code", event.target.value)
              }
              className="input"
            />

            <button type="reset" onClick={() => validateCoupon()}>
              {loadingCoupon ? "LOADING.." : "APPLY"}
            </button>
          </form>
        </div>

        <div className="titleCost">
          <div>Initial Cost</div>
          <div>Discount</div>
          <div>Final Cost</div>
        </div>

        <div className="dividerCoupon"></div>

        <div className="costValue">
          <div>{`$ ${Number(realPrice).toFixed(2)}` || "Unavailable"}</div>
          <div>
            {form?.values?.percent
              ? `${form?.values?.percent} %`
              : `$ ${Number(form?.values?.amount).toFixed(2)}`}
          </div>
          <div>
            {`$ ${Number(
              form?.values?.percent
                ? realPrice - (realPrice * form?.values?.percent) / 100
                : realPrice - form?.values?.amount
            ).toFixed(2)}`}
          </div>
        </div>
      </div>
      <button
        onClick={() => form.submitForm()}
        className="buttonNewProperty"
        disabled={loading}
      >
        {loading ? "LOADING..." : "SCHEDULE CLEANING"}
      </button>
    </div>
  );
};

export default Manual;
