import React, { useContext, useEffect, useState } from "react";
import { ComponentModal } from '../../Components/ComponentModal';
import CardList from '../NewProperty/Components/CardList';
import Trash from '../../Assets/lixo.svg';
import Add from '../../Assets/add.svg';
import api from "../../Service/api";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const Tasks = ({ form, onClick, loading, addresses }) => {
  const [newItem, setNewItem] = useState('');
  const [selected, setSelected] = useState("");
  const [showImport, setShowImport] = useState(false);
  const [taskList, setTaskList] = useState(addresses?.map(function (item) {
    return {value: `${item.addressNickname} - items: ${item.addressChecklists.length}`, key: item.id};
  }));

  const addChecklistTask = (event) => {
    const name  = event.target.value.name;
    const id = event.target.value.id;
    setSelected(name);
    const itens = addresses?.filter((value) => value.id === id).map(function (item) {
      return item?.addressChecklists?.map(function (itemt) {
        return { description: itemt?.description };
      })
    })

    form.setFieldValue("tasksChecklist", [...form.values.tasksChecklist, ...itens[0]]);
  };

  let newList = () => {
    if (newItem !== "") {
      setNewItem('');
      form.setFieldValue("tasksChecklist", [...form.values.tasksChecklist, {description: newItem}])
    } else {
      return alert('Enter the task name')
    }
  };

  const removeItem = (item) => {
    const list = form.values.tasksChecklist.map((value) => value.description !== item ? value : {...value, delete: true});
    form.setFieldValue("tasksChecklist", list)
  };
  
  // console.log(addresses);

  const checklistItems = [
    "Mop floor",
    "Make beds",
    "Dust windows and blinds",
    "Remove cobwebs",
    "Dust/vacuum furniture",
    "Clean sinks and countertops",
    "Wet wipe cabinets and doors",
    "Clean and disinfect toilets, tubs and showers",
    "Vacuum floor, carpets, rugs and stairs",
    "Clean appliance exterior",
    "Clean microwave",
    "Load dishwasher",
    "Pick up and straighten decorative items",
    "Dust sills, ledges, baseboards and wall hangings",
    "Laundry*",
  ];

  return (
    <div>
      <div style={{ maxWidth: "721px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
          <span>
            <div className="containerTextList">
              We have a
              <ComponentModal className="listOfTasks" disableButton={true} text="list of tasks">
                <p className="titleList">list of tasks</p>
                <CardList list={checklistItems} />
              </ComponentModal> 
              for your cleaner.
            </div>

            <p className="subtitlePageProperty" style={{ textAlign: "justify"}}>
              Add your own checklist or special requests if you want. If our
              list is enough, you can skip this part and click 'Save'.
            </p>
          </span>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          {showImport ? (
            <Box>
              <FormControl>
                <Select
                  labelId="demo"
                  disabled={taskList?.length > 0 ? false : true}
                  value={selected}
                  renderValue={(sel) => {
                    if (sel.length === 0) {
                      return <em>Select property</em>;
                    }

                    return sel;
                  }}
                  label="Name"
                  onChange={addChecklistTask}
                  className="inputProperty"
                  sx={{
                    color: "rgba(0, 0, 0, 0.87)",
                    height: "30px",
                  }}
                  // displayEmpty
                >
                  {taskList.map((value, idx) => (
                    <MenuItem
                      key={idx}
                      value={{ name: value.value, id: value.key }}
                    >
                      {value.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <button
              onClick={() => setShowImport(true)}
              borderNone
              style={{
                width: "163px",
                height: "30px",
                backgroundColor: "#1D4E92",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              IMPORT CHECKLIST
            </button>
          )}
        </div>

        <div className="containerNewList">
          {form.values.tasksChecklist.length > 0 ? (
            form?.values?.tasksChecklist?.map((value, idx) => (
             !value.delete ? (
              <div key={idx}>
                <span>{value.description}</span>
                <button onClick={() => removeItem(value.description)}><img src={Trash} alt="trash" /></button>
              </div>
             ) : null
            ))
          ) : null}
        </div>

        <form className="containerAddTak">
          <div className="conatainerStep7Input">
            <input
              placeholder="New Item"
              onChange={(event) => setNewItem(event.target.value)}
              className="input"
              style={{
                width: "88%",
              }}
            />

            {/* <button className="button" onClick={newList} type="reset">
              <img src={Add} alt="Add" />
              <span>NEW ITEM</span>
            </button> */}

            <input
              value="ADD"
              className="inputReset"
              type="reset"
              onClick={newList}
              style={{
                backgroundColor: "#7cc8e4",
                border: "none",
                color: "#fff",
                width: "10%",
                cursor: "pointer",
              }}
            />
          </div>
        </form>

        <button onClick={onClick} className="buttonNewProperty">
          {loading ? "SAVING..." : "SAVE"}
        </button>
      </div>
    </div>
  );
};

export default Tasks;
