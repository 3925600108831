import React from "react";
import { Box } from "@mui/system";
import { Container } from "@mui/material";

export const ContentContainer = ({ children }) => (
  <Box
    component="main"
    sx={{
      flexGrow: 1,
      overflow: "auto",
      paddingTop: 5,
    }}
  >
    <Container maxWidth="100%" sx={{ height: "95%" }}>
      {children}
    </Container>
  </Box>
);
