import React from "react";
import moment from "moment";
import placeholder from "../../../Assets/placeholder.png";
import CardScheduleDetail from "./CardScheduleDetail";

import "./CalendarCard.css";

const CalendarCard = ({ schedule, goBack }) => {
  const capitalize = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.substr(1);
  };

  return (
    <div
      className="containerCardCalendar cardMarginRigth"
      style={{ position: "relative" }}
    >
      {schedule?.attributes?.extended && (
        <div className="extended">Extended</div>
      )}
      <div className="containerLeft">
        <img
          alt=""
          src={
            schedule.attributes.cleaning.property_image
              ? schedule.attributes.cleaning.property_image.url
              : placeholder
          }
        />
        <div
          className="containerInfoReview cardWidth"
          style={{ marginRight: "10px" }}
        >
          <div className="nameReview">
            {schedule.attributes.address?.address_nickname || "No nickname"}
          </div>
          <div className="textReview">{`${moment(
            schedule.attributes.date
          ).format("MM/DD")} • ${schedule.attributes.time}`}</div>
          <div className="space">
            <span className="textReview">{`Status: `}</span>
            <span className="textReview done">
              {capitalize(schedule.attributes.status)}
            </span>
          </div>
          <CardScheduleDetail
            schedule={schedule}
            id={schedule.id}
            goBack={goBack}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarCard;
