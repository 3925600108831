import React, { useState, useEffect } from "react";
import api from "../../../Service/api";
import Add from "../../../Assets/add.svg";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Components/Loader/index";
import AddCredCard from "./AddCredCard";
import Trash from "../../../Assets/trash-2.svg";

import "./Payment.css";

const Payment = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [reloadPage, setReloadPag] = useState(false);
  const [selected, setSelected] = useState(true);
  const [reloadCredCard, setReloadCredCard] = useState(false);
  const [pay, setPay] = useState([]);

  useEffect(() => {
    try {
      setLoading(true);
      async function fetchData() {
        const { data } = await api.get("/credit_cards");
        setPay(data);
        setLoading(false);
      }
      fetchData();
    } catch (error) {
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(`${error.response.data.errors[0].title}`);
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
    }
    setLoading(true);
    setReloadPag(false);
    setReloadCredCard(false);
  }, [reloadPage, reloadCredCard]);

  const setDefault = async (id, main, secundary) => {
    setLoading(true);
    try {
      await api.patch(`/credit_cards/${id}/set_default/?secondary_default=${secundary}&default=${main}`);
      
      // console.log({id, main, secundary});

      alert("Update successfull!", "Please log in again.");
      setReloadPag(true);
    } catch (error) {
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(`${error.response.data.errors[0].title}`);
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
    }

    setLoading(false);
  };

  const deleteCredCard = async (id) => {
    if (pay.length <= 1) {
      alert("Must have at least one registered card.");
    } else {
      try {
        await api.delete(`/credit_cards/${id}`);
        setReloadCredCard(true);
      } catch (error) {
        if (error?.response?.status) {
          if (error.response.status !== 401) {
            if (
              error?.response?.data?.message &&
              error?.response?.data?.message
            ) {
              alert(`${error.response.data.message}`);
            } else {
              alert("Something unexpected happened.", "Please try again.");
            }
          } else {
            alert("Session expired", "Please login in again.");
          }
        } else {
          alert("Something unexpected happened.", "Please try again.");
        }
      }
    }
  };

  // console.log(pay);

  return (
    <>
      {selected ? (
        <div style={{ maxWidth: 721 }}>
          {!loading ? (
            pay.map((value, idx) => (
              <div className="containerCredCard" key={idx}>
                <div className="containerButonsCredCard">
                  <button
                    className="deleteCleaningButton"
                    onClick={() => deleteCredCard(value.id)}
                  >
                    DELETE
                  </button>
                  <button
                    className={
                      value.attributes.default ? "buttonMain" : "buttonMakeMain"
                    }
                    onClick={() => setDefault(value.id, true, false)}
                  >
                    {value.attributes.default ? "Main" : "Set main"}
                  </button>

                  {value.attributes.default !== true && (
                      <button
                        className={
                          value.attributes.secondaryDefault
                            ? "buttonMain"
                            : "buttonMakeMain"
                        }
                        style={{ marginLeft: '10px'}}
                        onClick={() =>
                          setDefault(
                            value.id,
                            false,
                            !value.attributes.secondaryDefault
                          )
                        }
                      >
                        {value.attributes.secondaryDefault
                          ? "Second main"
                          : "Set second main"}
                      </button>
                    )}
                </div>
                <div className="containerInfoCards">
                  <div>{value.attributes.brand}</div>
                  <div>{`**** **** **** ${value.attributes.last4digits}`}</div>
                </div>
              </div>
            ))
          ) : (
            <Loader />
          )}
          <button
            className="buttonCredCard"
            onClick={() => setSelected(false)}
            type="reset"
          >
            <img src={Add} alt="Add" />
            <span>Add credit card</span>
          </button>
        </div>
      ) : (
        <AddCredCard selected={setSelected} />
      )}
    </>
  );
};

export default Payment;
