import React from "react";
import moment from "moment";
// import { Button } from "../../../Components/Button";
import placeholder from "../../../Assets/placeholder.png";
import { Rating, Box} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import SendReview from './SendReview';

import "./ReviewCard.css";


const ReviewCard = ({ schedule, star }) => {
  const {
    cleanerReview,
    // address,
    cleaner,
    status,
    clientReview
  } = schedule;

  // console.log(schedule);

  const capitalize = str => {
    if (typeof str !== 'string') {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.substr(1);
  }

  return (
    <div className="containerCardReview">
      <div className="containerLeft">
        <img
          alt=""
          src={
            cleanerReview?.cleanPlacePhotos !== null
              ? cleanerReview?.cleanPlacePhotos[0]
              : placeholder
          }
        />
        <div className="containerInfoReview">
          <div className="nameReview">
            {schedule.address?.address_nickname || "No nickname"}
          </div>
          <div className="adressReview">
            {schedule.address?.address || "No address"}
          </div>
          <div className="textReview">{`${moment(schedule.date).format(
            "MM/DD"
          )} • ${schedule.time} • ${cleaner}`}</div>
          <span className="textReview">{`Status: `}</span>
          <span className="textReview done">{capitalize(status)}</span>
        </div>
      </div>
      {star ? (
        <SendReview schedule={schedule} />
      ) : (
        <Box
          sx={{
            marginRight: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Rating
            name="text-feedback"
            size="large"
            value={clientReview.nps}
            style={{ color: "#7CC8E4" }}
            readOnly
            precision={0.5}
            emptyIcon={
              <StarIcon style={{ color: "#0E1F41" }} fontSize="inherit" />
            }
          />
        </Box>
      )}
    </div>
  );
};

export default ReviewCard;
