import React from 'react';
import Box from '@mui/material/Box';

export const InputsContainer = ({children}) => {
  return (
    <Box
      component="form"
      // sx={{
      //   '& > :not(style)': { m:"2%" , width: '100%',  },
      // }}
      style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}
      noValidate
      autoComplete="off"
    >
      {children}
    </Box>
  );
};
