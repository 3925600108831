import React from "react";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import "./Calendar.css";

const Calendar = ({
  selectedMonth,
  selectedYear,
  onChange,
  selectedDay,
  setSelectedDay,
  allSchedules,
  loading,
}) => {

  const months = moment.months();
  const startYear = 2018;
  const endYear = moment().year() + 2;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );

  const selectDate = (date) => {
    setSelectedDay(date);
  };

  const checkDate = (data) =>
    allSchedules.some(
      (item) => item.attributes.date === moment(data).format("YYYY-MM-DD")
    );

  const renderDays = () => {
    const days = [];

    const startOfMonth = moment()
      .year(selectedYear)
      .month(selectedMonth)
      .startOf("month")
      .day(0);
    const endOfMonth = moment()
      .year(selectedYear)
      .month(selectedMonth)
      .endOf("month")
      .day(6);
    let day = startOfMonth.clone();

    while (day <= endOfMonth) {
      days.push(day);
      day = day.clone().add(1, "d");
    }

    const daysBefore = startOfMonth.diff(
      moment().year(selectedYear).month(selectedMonth).startOf("month"),
      "days"
    );
    for (let i = 1; i <= daysBefore; i++) {
      const newDay = startOfMonth.clone().subtract(i, "d");
      days.unshift(newDay);
    }

    const daysAfter = endOfMonth.diff(
      moment().year(selectedYear).month(selectedMonth).endOf("month"),
      "days"
    );

    for (let i = 1; i <= -daysAfter; i++) {
      const newDay = endOfMonth.clone().add(i, "d");
      days.push(newDay);
    }

    while (days.length < 42) {
      if (daysBefore > daysAfter) {
        const newDay = startOfMonth.clone().subtract(days.length, "d");
        days.unshift(newDay);
      } else {
        const newDay = endOfMonth.clone().add(days.length - days.length, "d");
        days.push(newDay);
      }
    }

    return days;
  };


  return (
    <div className="calendar">
      <div className="header">
        <select
          value={selectedMonth}
          onChange={(e) => onChange(e.target.value, selectedYear)}
          className="selectCalendar"
        >
          {months.map((month, index) => (
            <option key={index} value={index}>
              {month}
            </option>
          ))}
        </select>
        <select
          value={selectedYear}
          onChange={(e) => onChange(selectedMonth, e.target.value)}
          className="selectCalendar"
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <div className="weekdays">
        <div className="weekday">SU</div>
        <div className="weekday">MO</div>
        <div className="weekday">TU</div>
        <div className="weekday">WE</div>
        <div className="weekday">TH</div>
        <div className="weekday">FR</div>
        <div className="weekday">SA</div>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "20vh",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <CircularProgress size={50} />
          </Box>
        </div>
      ) : (
        <div className="">
          <>
            {renderDays()
              .reduce((rows, day, i) => {
                if (i % 7 === 0) rows.push([]);
                rows[rows.length - 1].push(day);
                return rows;
              }, [])
              .map((week, i) => (
                <div key={i} className="days">
                  {week.map((day, j) => {
                    const isCurrentMonth = day.month() === selectedMonth;
                    const isToday = day.isSame(moment(), "day");
                    const textColor = isCurrentMonth ? "#000000" : "#a5aebd";
                    const isSelected =
                      selectedDay &&
                      moment(selectedDay).format("YYYY-MM-DD") ===
                        moment(day).format("YYYY-MM-DD");

                    return (
                      <div
                        key={j}
                        style={{
                          color: textColor,
                        }}
                        className={`day ${isSelected ? "selected" : ""} ${
                          isToday ? "today" : ""
                        } ${checkDate(day) ? "random" : ""}`}
                        onClick={() => selectDate(day)}
                      >
                        {moment(day).format("DD")}
                      </div>
                    );
                  })}
                </div>
              ))}
          </>
        </div>
      )}
    </div>
  );
};

export default Calendar;
