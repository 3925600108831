import React from "react";

import Illustration1 from '../../../../../Assets/image 3.svg';

import './Screen.css';

const Screen6 = () => {
  return (
    <div className="containerCardScren">
      <div className="titleTextOnboard2">Detailed info</div>
      <div
        style={{
          display: "flex",
          width: "80%",
          marginTop: "15px",
          gap: "10px",
        }}
      >
        <img
          src={Illustration1}
          alt="dsa"
          className="img"
          style={{ width: "16vw" }}
        />

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <div className="textScren6" style={{ marginBottom: '20px'}}>
            The yellow button edits <strong>the check-in and check-out</strong>
          </div>

          <div className="textScren6" style={{ marginBottom: '20px'}}>
            The red button <strong>deletes the cleaning</strong>
          </div>

          <div className="textScren6">
            Check address, date, time, status, cleaner, value
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screen6;
