import React, { useState } from "react";
import { Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";

import './CardPhoto.css';
import { Box } from "@mui/system";

const CardPhoto = ({ photo, onClick, form, id, description }) => {
  
  const handleDefault = (index) => {
    form.values.photos.map((image, localIndex) => {
      if (localIndex === index) {
        return form.setFieldValue(`photos[${localIndex}].default`, true);
      } else {
        return form.setFieldValue(`photos[${localIndex}].default`, false);
      }
    });
  };

  // console.log(form.values);
  return (
    <div className="containerCardPhotos">
      <img src={photo} alt="images" />
      <FormControlLabel
        control={
          <Checkbox
            checked={form?.values?.photos[id]?.default || false}
            onChange={() => handleDefault(id)}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Cover image"
      />

      <Box
        component="form"
        sx={{
          '& > :not(style)': { width: '100%', marginTop: 0 },
        }}
        noValidate
        autoComplete="off"
        onChange={(text) => {
          form.setFieldValue(`photos[${id}].description`, text.target.value)
        }}
      >
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={2}
          defaultValue={description}
        />
      </Box>

      <button onClick={onClick}>Delete photo</button>
    </div>
  );
};

export default CardPhoto;
