import React, { useContext, useState, useEffect } from "react";
import api from "../../../Service/api";
import { User, Key, Calendar, DollarSign } from 'react-feather';

import Automated from './Automated';

const Ical = ({ form, loading, property}) => {
  const [loadingCost, setLoadingCost] = useState(true);
  const [price, setPrice] = useState("0");

  const checkLinkIcal = () => {
    if (form.values.icalendarUrls.length <= 0) {
      return alert("Please add at least one ical link")
    } else {
      return form.submitForm()
    }
  }

  useEffect(() => {
    getCost();
  }, []);

  const getCost = async () => {
    try {
      setLoadingCost(true);
      const { data } = await api.post("/addresses/price", {
        data: {
          attributes: {
            bedroomsAmount: form?.values?.bedrooms,
            bathroomsAmount: form?.values?.bathrooms,
          },
        },
      });

      if (data) {
        setPrice(`${data?.attributes?.price} USD` || "Unavailable");
      }
    } catch (error) {
      setPrice("Unavailable");
    } finally {
      setLoadingCost(false);
    }
  };

  // console.log(form);
  return (
    <>
      <div style={{ maxWidth: "721px", marginTop: 40 }}>
        <Automated form={form} />
      
        {/* <button onClick={() => checkLinkIcal()} className="buttonNewProperty">
          {loading ? "SAVING..." : "SAVE"}
        </button> */}
      </div>
    </>
  );
};

export default Ical;
