import React from "react";

export const Button = ({
  text,
  onClick,
  edit,
  style,
  disabled,
  iconName,
  iconSize,
}) => {
  const buttonStyles = edit ? styles.edit : styles.primary;

  return (
    <button
      style={{
        ...buttonStyles,
        ...style,
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {iconName ? (
        <>
          <span style={edit ? styles.editText : styles.primaryText}>
            {text}
          </span>
          <div style={styles.icon} name={iconName} size={iconSize} />
        </>
      ) : (
        <span style={edit ? styles.editText : styles.primaryText}>
          {text}
        </span>
      )}
    </button>
  );
};

const styles = {
  primary: {
    alignItems: "center",
    backgroundColor: "#86C71C",
    border: "none",
    borderRadius: 30,
    fontSize: 12,
    fontWeight: "500",
    justifyContent: "center",
    padding: 15,
    cursor: "pointer",
  },
  primaryText: {
    color: "#fff",
    fontWeight: "bold",
  },
  edit: {
    alignItems: "center",
    backgroundColor: "#1D4E92",
    border: "none",
    borderRadius: 30,
    fontSize: 12,
    fontWeight: "500",
    justifyContent: "center",
    padding: 15,
    cursor: "pointer",
  },
  editText: {
    color: "#fff",
    fontWeight: "bold",
    marginLeft: 5,
    marginRight: 5,
  },
  icon: {
    color: "#FFF",
    marginTop: 5,
  },
};
