import React, { useState, useContext } from "react";
import api from "../../../Service/api";
import { AuthorizedLayout } from "../../../Components/AuthorizedLayout";
import * as yup from "yup";
import { useFormik } from "formik";
import { Input } from "../../../Components/Input";
import { InputsContainer } from "../../../Components/InputsContainer";
import AppContext from "../../../Context/AppContext";

const ChangePassword = () => {
  const { signOut } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const updateClient = async () => {
    setLoading(true);

    try {
      await api.put("update_profile", { data: { attributes: form.values } });

      alert("Update successfull!", "Please log in again.");
      signOut();
    } catch (error) {
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(
              `${error.response.data.errors[0].title}`
            );
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
    }

    setLoading(false);
  };

  const form = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: yup.object({
      currentPassword: yup.string().required("current password is a required field"),
      password: yup.string().min(8).required(),
      passwordConfirmation: yup
        .string()
        .oneOf([yup.ref("password"), null], "passwords must match")
        .required("password confirmation is a required field"),
    }),
    onSubmit: updateClient,
  });

  return (
    <AuthorizedLayout>
      <p className="titlePage">Change password</p>
      <p className="subtitlePage" style={{ width: "320px", marginBottom: 50 }}>
        Edit your info
      </p>
      <div style={{ maxWidth: 721 }}>
        <InputsContainer>
          <Input
            placeholder="Current password"
            field="currentPassword"
            form={form}
            assistiveText={{ color: "#0E1F41" }}
            keyboardType={"password"}
          />
        </InputsContainer>

        <InputsContainer>
          <Input
            placeholder="New password"
            field="password"
            form={form}
            assistiveText={{ color: "#0E1F41" }}
            keyboardType={"password"}
          />

          <Input
            placeholder="Confirm password"
            field="passwordConfirmation"
            form={form}
            keyboardType={"password"}
            assistiveText={{ color: "#0E1F41" }}
          />
        </InputsContainer>

        <button onClick={form.submitForm} className="buttonNewProperty">
          {loading ? "LOADING..." : "CHANGE PASSWORD"}
        </button>
      </div>
    </AuthorizedLayout>
  );
};

export default ChangePassword;
