import React from "react";

import Illustration1 from '../../../../../Assets/Img (1).svg';

import './Screen.css';

const Screen5 = () => {
  return (
    <div className="containerCardScren">
      <div className="titleTextOnboard2">Filter schedule cleanings by property</div>
      <img
        src={Illustration1}
        alt="dsa"
        className="img"
        style={{ width: "20vw", marginTop: '4vh' }}
      />
      <div className="suntitleTextOnborad">
        Using the filter you can see all cleanings from a specific property
      </div>
    </div>
  );
};

export default Screen5;
