import React, { useState, useContext } from "react";
import {
  Divider,
  CardMedia,
  CardContent,
  CardActions,
  Card,
  Box,
  Rating,
  TextField,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { Button } from "../../../../Components/Button";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import placeholder from "../../../../Assets/placeholder.png";
import api from "../../../../Service/api";
import AppContext from "../../../../Context/AppContext";
import * as yup from "yup";
import { useFormik } from "formik";

import "./SendReview.css";

const BackdropUnstyled = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

BackdropUnstyled.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //width: "375px",
  bgcolor: "background.paper",
  borderRadius: 2,
  // border: '2px solid #000',
  boxShadow: 0,
  display: "flex",
  justifyContent: "center",
  p: 0,
};

export default function ScheduleReview({
  schedule,
  readOnly,
  disabled,
  setDisabledReview,
}) {
  const { setRealoadPage } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(2);
  // const [hover, setHover] = useState(-1);

  const { cleanerReview, cleaner } = schedule;

  const form = useFormik({
    initialValues: {
      stars: schedule?.clientReview?.nps || 3,
      reviewText: schedule?.clientReview?.description || "",
    },
    onSubmit: (formValues) => sendReview(formValues),

    validationSchema: yup.object({
      reviewText: yup.string().required("Review message is required!"),
    }),
  });

  // console.log(cleanerReview);

  const sendReview = async (formValues) => {
    setLoading(true);
    setRealoadPage(true);
    try {
      await api.post(`/client_reviews`, {
        data: {
          attributes: {
            scheduleId: Number(schedule.id),
            nps: formValues.stars,
            description: formValues.reviewText,
            compliments: ["attentive", "polite", "punctual"],
            wentWrong: [],
          },
        },
      });
      setDisabledReview(true);
      setOpen(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(`${error.response.data.errors[0].title}`);
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
    }
    setLoading(false);
    setRealoadPage(false);
  };

  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <Button
        text={disabled ? "Already reviewed" : "Review"}
        edit
        style={{
          height: 30,
          width: "95%",
          display: "flex",
          backgroundColor: disabled ? "lightgray" : "#1D4E92",
          color: disabled ? "#1D4E92" : "white",
        }}
        onClick={handleOpen}
        disabled={disabled}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <Card sx={{ width: 375 }}>
            <CardMedia
              component="img"
              height="170px"
              image={
                cleanerReview?.cleanPlacePhotos !== null
                  ? cleanerReview?.cleanPlacePhotos[0]
                  : placeholder
              }
              alt="green iguana"
            />
            <CardContent>
              <div className="nameReview">
                {schedule.address?.address_nickname || "No nickname"}
              </div>
              <div className="textReview">{`${moment(schedule.date).format(
                "MM/DD"
              )} • ${schedule.time} • ${cleaner}`}</div>
              <Divider style={{ marginBottom: 15 }} />
              <Box
                sx={{
                  width: 200,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Rating
                  name="hover-feedback"
                  value={value}
                  // precision={0.5}
                  style={{ color: "#7CC8E4" }}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    form.setFieldValue("stars", value);
                  }}
                  emptyIcon={
                    <StarIcon style={{ color: "#0E1F41" }} fontSize="inherit" />
                  }
                />
              </Box>

              <Box
                component="form"
                sx={{
                  "& > :not(style)": { width: "100%", marginTop: 2 },
                }}
                noValidate
                autoComplete="off"
                onChange={(text) => {
                  form.setFieldValue("reviewText", text.target.value);
                }}
              >
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={3}
                  label="Write a review"
                />
              </Box>
            </CardContent>
            <CardActions>
              <button
                variant="contained"
                className="buttonSendReview"
                type="reset"
                onClick={form.submitForm}
              >
                {loading ? 'LOADING...' : 'SAVE'}
              </button>
            </CardActions>
          </Card>
        </Box>
      </Modal>
    </div>
  );
}
