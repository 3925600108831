import React, { useState } from "react";
import Logo from "../../Assets/Group 807.svg";
import Frist from "./Components/Frist";
import SignUp from "./Components/SignUp";

import "./Login.css";

const Login = () => {
  const [screenSlected, setScreenSelected] = useState(true);
  return (
    <div className="containerBackgroundLogin">
      <div className="containerLogin">
        <img src={Logo} alt="logo" className="logoSett" />

        <div className="containerSelectScreenLogin">
          <div
            className={screenSlected && "selected"}
            onClick={() => setScreenSelected(true)}
          >
            Login
          </div>
          <div
            className={!screenSlected && "selected"}
            onClick={() => setScreenSelected(false)}
          >
            Sign Up
          </div>
        </div>

        {screenSlected ? (
          <Frist setScreenSelected={setScreenSelected} />
        ) : (
          <SignUp setScreenSelected={setScreenSelected} />
        )}
      </div>
    </div>
  );
};

export default Login;
