import React, { useContext, useState } from "react";
import { Checkbox, FormControlLabel, FormGroup, Divider } from "@mui/material";
import Trash from "../../../Assets/trash-2.svg";
import Add from "../../../Assets/add.svg";

const Automated = ({ form }) => {

  const [newItem, setNewItem] = useState([]);

  let newList = () => {
    if (newItem.url !== "" && newItem.name !== "") {
      form.setFieldValue("icalendarUrls", [...form.values.icalendarUrls, newItem]);
      setNewItem([]);
      form.submitForm()
    } else {
      return alert("Enter the iCalLinks");
    }
  };

  const handleChange = (type) => {
    if(type === "chekout") {
      form.setFieldValue("cleaningOn", true);
      form.submitForm()
    } else {
      form.setFieldValue("cleaningOn", false);
      form.submitForm()
    }
  };

  const handleInput = (e, name) => {
    let input = e.target.value;

    setNewItem((prevState) => {
      return {...prevState, [name]: input};
    })
  }

  // console.log(newItem);

  const removeItem = (item) => {
    const list = form.values.icalendarUrls.map((value) => value !== item ? value : {...value, delete: true});
    form.setFieldValue("icalendarUrls", list);
    form.submitForm()
  };
  
  // const removeItem = (item) => {
  //   const list = form.values.consumablesChecklist.map((value) => value.description !== item ? value : {...value, delete: true});
  //   form.setFieldValue("consumablesChecklist", list)
  // };

  // console.log(form.values);

  return (
    <>
      <div className="subtitleIcall">Add one or many iCall links</div>

      <div className="linkContainer">
        {form.values.icalendarUrls.length > 0
          ? form.values.icalendarUrls.map((value, idx) =>
              !value.delete ? (
                <div key={idx}>
                  <span>{`${value.name} • ${value.url}`}</span>
                  <button onClick={() => removeItem(value)}>
                    <img src={Trash} alt="trash" />
                  </button>
                </div>
              ) : null
            )
          : null}
      </div>
      <form className="containerAddTak">
        <div className="conatainerStep7Input">
          <input
            placeholder="iCall label"
            onChange={(event) => handleInput(event, "name")}
            className="input"
          />

          <input
            placeholder="iCall link"
            onChange={(event) => handleInput(event, "url")}
            className="input"
          />

          <input
            value="ADD"
            className="inputReset"
            type="reset"
            onClick={newList}
            style={{
              backgroundColor: "#7cc8e4",
              border: "none",
              color: "#fff",
              width: "10%",
              cursor: "pointer",
            }}
          />
        </div>
        {/* <button className="button" onClick={newList} type="reset">
            <img src={Add} alt="Add" />
            <span>ADD</span>
          </button> */}
      </form>

      <Divider style={{ marginTop: 18, marginBottom: 20, maxWidth: 721 }} />

      <FormGroup
        style={{ marginTop: 20, display: "flex", flexDirection: "row", marginBottom: 50 }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={form.values.cleaningOn}
              onChange={() => handleChange("chekout")}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Cleaning on checkout"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={!form.values.cleaningOn}
              onChange={() => handleChange("chekin")}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Cleaning on checkin"
        />
      </FormGroup>
    </>
  );
};

export default Automated;