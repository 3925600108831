import React, { useEffect, useState } from 'react';
import { AuthorizedLayout } from '../../Components/AuthorizedLayout';
import api from '../../Service/api';

import alert from "../../Assets/alert-triangle.svg";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import Completed from "../../Assets/check.svg";
import Started from "../../Assets/clock.svg";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Trash from "../../Assets/lixo.svg";

import './Notifications.css';
import moment from 'moment';

const Notifications = () => {
  const [loading, setLoading] = useState(false);
  const [reloadNotifications, setReloadNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);

  // console.log(notifications);

  useEffect(() => {
    const fetchNotifications =  async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/notifications");
        setNotifications(data);
        setLoading(false)
      } catch(error) {
        //console.log(_error);
        if (error?.response?.status) {
          if (error.response.status !== 401) {
            if (
              error?.response?.data?.errors &&
              error?.response?.data?.errors[0]?.title
            ) {
              alert(
                `${error.response.data.errors[0].title}`
              );
            } else {
              alert("Something unexpected happened.", "Please try again.");
            }
          } else {
            alert("Session expired", "Please login in again.");
          }
        } else {
          alert("Something unexpected happened.", "Please try again.");
        }
      }
      setLoading(false);
    }
    fetchNotifications();
  },[reloadNotifications])

  const deleteNotifications =  async (id) => {
    try {
      await api.delete(`/notifications/${id}`);
      setReloadNotifications(!reloadNotifications)
    } catch(error) {
      // console.log(error);
    }
  }

  const checkIcon = (icon) => {
    switch (icon) {
      case 'Cleaning Started':
        return <img src={Started} alt="start icon" />;
      case 'Cleaning Completed':
        return <img src={Completed} alt="completed icon" />;
      case 'Deep Cleaning Alert!':
        return <CleaningServicesIcon sx={{ color: "#1d4e92" }}/>
      case 'Cleaning Alert!':
        return <img src={alert} alt="alert icon" />;
      default:
        return <DisabledByDefaultIcon sx={{ color: "#1d4e92" }}/>;
    }
  }

  // console.log(notifications);

  return (
    <AuthorizedLayout>
      <p className="titlePage">Notifications</p>
      <p className="subtitlePage" style={{ width: "320px" }}>
        Realtime follow up
      </p>
      <div className="containerCardNotifications">
        {notifications.map((values, idx) => (
          <div key={idx} className="containerInfoCard">
            <div className="left">
              {checkIcon(values.attributes.title)}
              <div>{`${values.attributes.title} of property ${
                values.attributes.addressName
              } day ${moment(values.attributes.createdAt).format(
                "YYYY-MM-DD"
              )} at ${moment(values.attributes.createdAt).format(
                "hh:mm a"
              )}`}</div>
            </div>

            <button onClick={() => deleteNotifications(values.id)}>
              <img src={Trash} alt="trash" />
            </button>
          </div>
        ))}
      </div>
    </AuthorizedLayout>
  );
}

export default Notifications;
