import React from 'react';
import Routers from './Routers/Routers';
import AppProvider from './Context/AppProvider';
import NewPropertyProvider from './Context/NewPropertyProvider';

function App() {
  return (
    <AppProvider>
      <NewPropertyProvider>
        <Routers />
      </NewPropertyProvider>
    </AppProvider>
  );
}

export default App;
