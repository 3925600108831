import React from "react";
import { Divider, List, Toolbar, Box } from "@mui/material";

import { MainListItems } from "./listItems";
// import logo from "../../assets/wecoffelogo2.svg";

import './SideBar.css';

export const MainSidebar = () => {
  return (
    <div className='containerSideBar'>
      <Box className='marginLeftSideBar'>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px: [1],
          }}
        >
          {/* <img src={logo} alt={""} style={{ width: "50%", margin: "20px 0" }} /> */}
        </Toolbar>
        <Divider />
        <List>
          <MainListItems />
        </List>
      </Box>
    </div>
  );
};
