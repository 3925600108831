import React, { useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AppContext from '../Context/AppContext';
import { AuthorizedLayout } from '../Components/AuthorizedLayout';

import Login from '../Pages/Login/Login';
import LoginGoogleMobile from '../Pages/LoginGoogleMobile/LoginGoogleMobile';
import ResetPassword from '../Pages/Login/ResetPassword';
import Properties from '../Pages/Properties/Properties';
import Reviews from '../Pages/Reviews/Reviews';
import Schedules from '../Pages/Schedules';
import Notifications from '../Pages/Notifications';
import Profile from '../Pages/Profile';
import ChangePassword from '../Pages/Profile/Components/ChangePassword';

import Billings from '../Pages/Billings';

// News Properties
import Step1 from '../Pages/NewProperty/Step1';
import Step2 from '../Pages/NewProperty/Step2';
import Step3 from '../Pages/NewProperty/Step3';
import Step4 from '../Pages/NewProperty/Step4';
import Step5 from '../Pages/NewProperty/Step5';
import Step6 from '../Pages/NewProperty/Step6';
import Step7 from '../Pages/NewProperty/Step7';
import AllDone from '../Pages/NewProperty/AllDone';

// Edit Properties
import EditProperty from '../Pages/EditProperty';

const Routers = () => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />

      <Route
        exact
        path="/authroute/googleauth/:id"
        element={
          <PublicRoute>
            <LoginGoogleMobile />
          </PublicRoute>
        }
      />

      <Route
        exact
        path="/ForgotPassword"
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />

      <Route
        exact
        path="/properties"
        element={
          <PrivateRoute>
            <Properties />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/Reviews"
        element={
          <PrivateRoute>
            <Reviews />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/Profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/ChangePassword"
        element={
          <PrivateRoute>
            <ChangePassword />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/Billings"
        element={
          <PrivateRoute>
            <Billings />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/property/:id"
        element={
          <PrivateRoute>
            <EditProperty />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/Step1"
        element={
          <PrivateRoute>
            <Step1 />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/Step2"
        element={
          <PrivateRoute>
            <Step2 />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/Step3"
        element={
          <PrivateRoute>
            <Step3 />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/Step4"
        element={
          <PrivateRoute>
            <Step4 />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/Step5"
        element={
          <PrivateRoute>
            <Step5 />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/Step6"
        element={
          <PrivateRoute>
            <Step6 />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/Step7"
        element={
          <PrivateRoute>
            <Step7 />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/AllDone"
        element={
          <PrivateRoute>
            <AllDone />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/Schedules"
        element={
          <PrivateRoute>
            <Schedules />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/Notifications"
        element={
          <PrivateRoute>
            <Notifications />
          </PrivateRoute>
        }
      />

      <Route path="*" element={<h1>404 Not found</h1>} />
    </Routes>
  );
};

export default Routers;

function PublicRoute({ children }) {
  const { user } = useContext(AppContext);

  if (user) {
    return <Navigate to="/Schedules" />;
  }

  return children;
}

function PrivateRoute({ children }) {
  const { user } = useContext(AppContext);

  if (!user) {
    return <Navigate to="/" />;
  }

  return children;
}
