import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import api from "../../../Service/api";
import moment from "moment";
import * as yup from "yup";
import { useFormik } from "formik";
// import Trash from "../../../Assets/trash-2.svg";
// import Add from "../../../Assets/add.svg";

// import "./ManualSchedule.css";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";

const Manual = ({ goBack, id, property, onClick }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const form = useFormik({
    initialValues: {
      checkoutDate: moment(property.date),
      checkinDate: moment(property.nextCheckinDate),
    },

    onSubmit: (values) => createSchedule(values),
  });

  const createSchedule = async (values) => {
    try {
      setLoading(true);
      await api.put(`schedules/${id}`, {
        data: {
          attributes: {
            //date: values.checkoutDate,
            date: `${moment(values.checkoutDate).format("YYYY-MM-DD")}`,
            //time: values.checkoutDate,
            time: `${moment(values.checkoutDate).format("HH:mm")}`,
            //nextCheckinDate: values.checkinDate,
            nextCheckinDate: `${moment(values.checkinDate).format(
              "YYYY-MM-DD"
            )}`,
            //nextCheckinTime: values.checkinDate,
            nextCheckinTime: `${moment(values.checkinDate).format("HH:mm")}`,
          },
        },
      });

      alert("Cleaning scheduled successfully!");
      goBack();
    } catch (error) {
      // console.log(error);
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(
              "An error has occurred." +
                ` ${error.response.data.errors[0].title}`
            );
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
      setLoading(false);
    }
  };


  // console.log(id);
  return (
    <div style={{ maxWidth: "721px" }}>
      {/* <button className="goBackButton" onClick={() => goBack()}>
        {"<"}
      </button> */}
      <form className="containerAddTak">
        <div className="conatainerStep7Input">
          {/* <input
            placeholder="iCall link title"
            onChange={(event) => handleInput(event, "title")}
            className="input"
          /> */}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="containerDateLabel">
              <div className="titleLabel">Checkout at</div>
              <MobileDateTimePicker
                className="buttonDate"
                // label="Checkout at"
                value={form.values.checkoutDate}
                onChange={(newValue) => {
                  form.setFieldValue("checkoutDate", newValue["$d"]);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>

            <div className="containerDateLabel">
              <div className="titleLabel">Checkin at</div>
              <MobileDateTimePicker
                className="buttonDate"
                // label="Checkin at"
                value={form.values.checkinDate}
                onChange={(newValue) => {
                  form.setFieldValue("checkinDate", newValue["$d"]);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </LocalizationProvider>

          {/* <button className="button" type="button">Checkout at</button>

          <button className="button" type="button">Checkin at</button> */}

          {/* <input
            placeholder="iCall link"
            onChange={(event) => handleInput(event, "link")}
            className="input"
          /> */}
        </div>
      </form>

      <div className="containerDateLabel" style={{ width: '100%', margin: '10px'}}>
        <div className="titleLabel">
          Please note that the cleaning schedule is based on the local time zone
          of the property.
        </div>
      </div>


      <div className="containerButtonDelete">
        <button className="close" onClick={onClick}>
          CLOSE
        </button>
        <button
          className="delete"
          onClick={() => form.submitForm()}
          style={{ backgroundColor: "#f5b21d" }}
        >
          {loading ? "UPDATING..." : "UPDATE"}
        </button>
      </div>

      {/* <button
        onClick={() => form.submitForm()}
        className="buttonNewProperty"
        disabled={loading}
      >
        {loading ? "LOADING..." : "SCHEDULE CLEANING"}
      </button> */}
    </div>
  );
};

export default Manual;
