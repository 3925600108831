import React from 'react';

export const Terms = () => (
  <div style={styles.background}>
    <div style={styles.container}>
      <div style={styles.title}>Sett Solutions Corp</div>
      <div style={styles.subtitle}>Service Agreement</div>

      <div style={styles.divider}>
        <div style={styles.bold}>1. </div>This Agreement constitutes a legal
        agreement between you ("you" or "Costumer") and Sett Solutions Corp
        ("Sett" or "us") an online platform with legal address sit at 9927 Abe
        Lincoln Rd, San Antonio, TX, USA 78240. This Agreement governs your use
        of our services throughout the platform. And ensure a clear and reliable
        communications between costumers what we offered through our website
        located at www.sett.solutions , as it may be modified, relocated and/or
        redirected from time to time (the "Site"), as well as the mobile
        applications offered by us (the "Apps"). Our services, platform, Site
        and Apps are collectively referred to as the "Sett Solutions Platform".
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>2. </div>By accessing, using or registering
        with the Sett Solutions Platform or any portions thereof, you hereby
        expressly acknowledge and agree to be bound by the terms and conditions
        of this Agreement, and any future amendments and additions to this
        Agreement as we may publish from time to time. Please read this
        Agreement carefully. If you do not agree to accept and be bound by this
        Agreement, you must immediately stop using the Sett Solutions Platform.
        Sett Solutions acceptance is expressly conditioned upon your assent to
        this Agreement in its entirety. If this Agreement is considered to be an
        offer by us, acceptance is expressly limited to this Agreement.
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>3. </div>By using the Sett Solutions
        Platform, you represent and warrant that:
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>(i) </div>You are at least 18 years old, are
        at least of the legally required age in the jurisdiction in which you
        reside, and are otherwise capable of entering into contracts and
        payments; and
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>(ii) </div>You have the right, authority and
        capacity to enter into this Agreement and to abide by the terms and
        conditions of this Agreement, and that you will so abide. Where you
        enter into this Agreement on behalf of a company or other organization,
        you represent and warrant that you have authority to act on behalf of
        that entity and to bind that entity to this Agreement.
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>4. </div>To the extent permitted and except
        where prohibited by applicable law, these Terms of Use include:
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>(i) </div>Your agreement that either party
        may compel binding arbitration for most types of disputes, and your
        agreement to submit to an informal dispute resolution process for at
        least 30 days prior to the initiation of any claim. Any controversies or
        disputes arising out of or relating to this Agreement will be resolved
        by binding arbitration under the rules of the American Arbitration
        Association at Texas State. The arbitrator's award will be final, and
        judgment may be entered upon it by any court having proper jurisdiction.
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>(ii) </div>Your agreement that no claims can
        be adjudicated on a class basis.
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>(iii) </div>Your agreement that the Sett
        Solutions Platform is provided "as is" and without warranty.
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>(iv) </div>Your agreement that the Sett
        Solutions Platform is platform providing a method for Professional
        Services to be booked, that professional services are performed by
        employees or third parties, called as contractors. Even these partners
        being as independent contractors, Sett Solutions Platform has the
        commitment to ensure that the service hired through Sett Solutions
        Website is done with the Sett Solutions quality and attending the
        client’s expectations.
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>(v) </div>Your acknowledgment of agreement to
        pay Sett Solutions Platform Fee that is hired online and sited in State
        of Texas will be applied to each completed job of a service requested
        through the Sett Solutions Platform. This fee can be increased according
        the allowance between the parts and each job or service requested by
        client that is not described on the services previous scheduled such as
        deep cleanings, laundry services or trash collection. For the addition
        fee the client will receive an invoiced with the description of what was
        charged.
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>(vi) </div>Your acknowledgment of agreement
        to Sett Solutions services cancellation policies and cancellation fees.
        That will be applied every time costumer cancels a service in the
        following rate: 80% with less than 3 hours before the service scheduled
        start.
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>(vii) </div>Your acknowledgment of agreement
        to cancel or schedule services 24 hours before the previous service
        start. Sett Solutions provides late time schedule services. But you
        might acknowledgment of the late schedule service depend of hour,
        professional availability and location.
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>(viii) </div>Your agreement that you will
        receive an additional extra service fee when the service provider claims
        for extra situations do not describe in the first proposal price, such
        as deep cleaning, party cleaning, trash remover, laundry service and
        others. The extra fee starts at $40.00.
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>(ix) </div>Your consent to any modifications
        made by Us (Sett Solutions), at any time, or amendments to this
        Agreement. You (Client) can request Us an amendment by writing withing
        30 days and if We accept both parts should sign the terms.
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>(x) </div>Your consent to the collection,
        use, sharing and transfer of your data as outlined in the Privacy Policy
        as updated from time to time.
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>(xi) </div>You hereby authorize us to use
        your any of the trademarks, service marks, trade names and logos, and
        content including photographs and descriptions, in the form or format
        that you supply to us or that you upload to our Site for use and posting
        on our Site and for use in marketing materials to be presented to
        Customers in online postings, via emails or otherwise to help promote
        you or your services. Sett Solutions reserves the right, in its sole
        discretion, to review, reject and remove any Partner Content that you
        upload to our Site or otherwise provide to us.
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>5. </div>The acknowledge that the service
        hired include:
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>(i) </div>Your agreement that no claims can
        be adjudicated on a class basis.
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>(ii) </div>Your agreement that the Sett
        Solutions Platform is provided "as is" and without warranty.
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>6. </div>I hereby acknowledge that I have
        read and understand these terms of use and privacy policy, and agree
        that my use of the Sett Solutions Platform is an acknowledgment of my
        agreement to be bound by all of the terms and conditions of this
        agreement.
      </div>

      <div style={styles.divider}>
        <div style={styles.bold}>
          BY CHECKING THE BOX STATING I AGREE TO THE SETT’S AGENCY ARGREEMENT,
          YOU HEREBY AGREE TO THIS INDEPENDENT SERVICE PROVIDER AGREEMENT.
        </div>
      </div>

      <div style={styles.divider}>
        You understand that your electronic signature is as legally binding as a
        handwritten signature.
      </div>
    </div>
  </div>
);

const styles ={
  divider: {
    marginBottom: 20,
    textAlign: "justify",
    // display: 'flex'
  },
  bold: {
    fontWeight: "bold",
  },
  container: {
    flex: 1,
    marginHorizontal: 20,
    marginVertical: 30,
  },
  background: {
    backgroundColor: "#fff",
    flex: 1,
  },
  title: {
    textAlign: "center",
    fontSize: 17,
    fontWeight: "bold",
    marginBottom: 0,
    fontStyle: "italic", 
  },
  subtitle: {
    textAlign: "center",
    fontSize: 17,
    fontWeight: "bold",
    fontStyle: "italic", 
  }
};
