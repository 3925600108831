import React, { useContext, useState } from "react";
import NewPropertyContext from "../../Context/NewPropertyContext";
import AppContext from "../../Context/AppContext";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import { ComponentModal } from "../../Components/ComponentModal";
import CardList from "./Components/CardList";
import Trash from "../../Assets/lixo.svg";
import Add from "../../Assets/add.svg";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from 'react-router-dom';

import "./NewProperty.css";

const Step5 = () => {
  const { step5Form } = useContext(NewPropertyContext);
  const { addresses } = useContext(AppContext);
  const [newItem, setNewItem] = useState("");
  const [selectedConsu, setSelectedConsu] = useState("");
  const [showImportConsu, setShowImportConsu] = useState(false);
  const [consumableList, setConsumableList] = useState(
    addresses?.map(function (item) {
      return {
        value: `${item?.addressNickname} - items: ${item?.cleaning?.consumables?.length}`,
        key: item.id,
      };
    })
  );

  const navigate = useNavigate();

  const addChecklistConsumable = (event) => {
    const name  = event.target.value.name;
    const id = event.target.value.id;
    setSelectedConsu(name);
    const itens = addresses?.filter((value) => value.id === id).map(function (item) {
      return item?.cleaning?.consumables?.map(function (itemt) {
        return itemt?.description
      })
    })

    step5Form.setFieldValue("consumables", [...step5Form.values.consumables, ...itens[0]]);
  };

  let newList = () => {
    if (newItem !== "") {
      setNewItem("");
      step5Form.setFieldValue("consumables", [...step5Form.values.consumables, newItem]);
    } else {
      return alert("Enter the task name");
    }
  };

  const removeItem = (item) => {
    const list = step5Form.values.consumables.filter((value) => value !== item);
    step5Form.setFieldValue("consumables", list);
  };

  // console.log(step5Form.values);

  const checklistItems = [
    "Bath tissue",
    "Hand soap",
    "Conditioner",
    "Shampoo",
    "Body wash",
    "Small trash bags",
    "Large trash bags",
    "Dish soap",
    "Dishwasher detergent",
    "Dish sponge",
    "Laundry detergent",
    "Paper towel",
    "Body towels",
    "Hand towels",
    "Face towels",
    "Set of beddings",
  ];

  return (
    <AuthorizedLayout>
      <div style={{ maxWidth: "721px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>
            <p className="titlePage">New property</p>
            <p className="subtitlePageProperty">Consumable Checklist</p>

            <div className="containerTextList">
              Missing any consumables that you provide to your guest from our
              <ComponentModal
                className="listOfTasks"
                disableButton={true}
                text="default list?"
              >
                <span className="titleList">Default list</span>
                <CardList list={checklistItems} />
              </ComponentModal>
            </div>

            <p
              className="subtitlePageProperty"
              style={{ textAlign: "justify" }}
            >
              Just add them here to help us track the inventory. Again, this is
              optional. You can skip it and click 'Next'.
            </p>
          </span>
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
          {showImportConsu ? (
            <Box>
              <FormControl>
                <Select
                  labelId="demo"
                  disabled={consumableList.length > 0 ? false : true}
                  value={selectedConsu}
                  renderValue={(sel) => {
                    if (sel.length === 0) {
                      return <em>Select property</em>;
                    }
        
                    return sel;
                  }}
                  label="Name"
                  onChange={addChecklistConsumable}
                  className="inputProperty"
                  sx={{
                    color: "rgba(0, 0, 0, 0.87)",
                    height: '30px'
                  }}
                  // displayEmpty
                >
                  {consumableList.map((value, idx) => (
                    <MenuItem
                      key={idx}
                      value={{ name: value.value, id: value.key }}
                    >
                      {value.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <button
              onClick={() => setShowImportConsu(true)}
              borderNone
              style={{
                width: "163px",
                height: "30px",
                backgroundColor: "#1D4E92",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              IMPORT CHECKLIST
            </button>
          )}
        </div>

        <div className="containerNewList">
          {step5Form.values.consumables.length > 0 ? ( step5Form.values.consumables.map((value, idx) => (
          <div key={idx}>
            <span>{value}</span>
            <button onClick={() => removeItem(value)}>
              <img src={Trash} alt="trash" />
            </button>
          </div>
          )) ) : null}
        </div>

        <form className="containerAddTak">
          <div className="conatainerStep7Input">
            <input
              placeholder="New Item"
              onChange={(event) => setNewItem(event.target.value)}
              className="input"
              style={{
                width: "88%",
              }}
            />

            <input
              value="ADD"
              className="inputReset"
              type="reset"
              onClick={newList}
              style={{
                backgroundColor: "#7cc8e4",
                border: "none",
                color: "#fff",
                width: "10%",
                cursor: "pointer",
              }}
            />

            {/* <button className="button" onClick={newList} type="reset">
              <img src={Add} alt="Add" />
              <span>NEW ITEM</span>
            </button> */}
          </div>
        </form>

        <button onClick={step5Form.submitForm} className="buttonNewProperty">
          NEXT
        </button>
        <button onClick={() => navigate(-1)} className="buttonNewProperty" style={{ marginTop: '10px'}}>
          GO BACK
        </button>
      </div>
    </AuthorizedLayout>
  );
};

export default Step5;
