import React, { useContext } from "react";
import NewPropertyContext from "../../Context/NewPropertyContext";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import { Input } from "../../Components/Input";
import { InputsContainer } from "../../Components/InputsContainer";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

import "./NewProperty.css";

const Step1 = () => {
  const { step1Form } = useContext(NewPropertyContext);
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    step1Form.setFieldValue("laundry", event.target.checked);
  };

  return (
    <AuthorizedLayout>
      <div style={{ maxWidth: "721px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>
            <p className="titlePage">New property</p>
            <p className="subtitlePageProperty">
              Add property information to schedule cleanings
            </p>
          </span>
        </div>
        <InputsContainer>
          <Input
            placeholder="Number of rooms"
            field="bedrooms"
            form={step1Form}
            keyboardType="number"
            assistiveText={{ color: "#0E1F41" }}
          />

          <Input
            placeholder="Number of bathrooms"
            field="bathrooms"
            form={step1Form}
            keyboardType="number"
            assistiveText={{ color: "#0E1F41" }}
          />

          <Input
            placeholder="Square feet"
            field="squareFeet"
            form={step1Form}
            keyboardType="number"
            assistiveText={{ color: "#0E1F41" }}
          />

          <Input
            placeholder="Other..."
            field="other"
            form={step1Form}
            assistiveText={{ color: "#0E1F41" }}
          />
        </InputsContainer>

        <FormGroup style={{ marginTop: 20}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="I want the cleaner to do the laundry"
          />
        </FormGroup>

        <button onClick={step1Form.submitForm} className="buttonNewProperty">NEXT</button>
      </div>
    </AuthorizedLayout>
  );
};

export default Step1;
