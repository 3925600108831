import React from "react";

import Illustration1 from '../../../../../Assets/image 1.svg';

import './Screen.css';

const Screen4 = () => {
  return (
    <div className="containerCardScren">
      <div className="titleTextOnboard2">Filter schedule cleanings by region</div>
      <img
        src={Illustration1}
        alt="dsa"
        className="img"
        style={{ width: "20vw", marginTop: '2vh' }}
      />
      <div className="suntitleTextOnborad">
        Using the filter you can see all cleanings from a specific region
      </div>
    </div>
  );
};

export default Screen4;
