import React, { useContext, useState } from "react";
import NewPropertyContext from "../../Context/NewPropertyContext";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Input } from "../../Components/Input";
import { useNavigate } from 'react-router-dom';

import "./NewProperty.css";

const Step3 = () => {
  const { step3Form } = useContext(NewPropertyContext);

  const navigate = useNavigate();

  // console.log(step3Form.errors.instructions);

  const verifyCheck = (name) => step3Form.values.way === name ? true : false;

  const [checked, setChecked] = useState([
    {value: "someone is home", checked: verifyCheck("someone_is_home")},
    {value: "doorcode", checked: verifyCheck("doorcode")},
    {value: "hidden key", checked: verifyCheck("hidden_key")},
    {value: "other", checked: verifyCheck("other")},
  ]);

  const toggleCheckbox = (value, index) => {
    const checkboxData = [...checked];

    for(let i = 0; i < checked.length; i += 1) {
      checked[i].checked =  false;
    }

    checked[index].checked = true;
    setChecked(checkboxData);
    step3Form.setFieldValue("way", value.value.replace(/\s/g, '_'))
  }

  return (
    <AuthorizedLayout>
      <div style={{ maxWidth: "721px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>
            <p className="titlePage">New property</p>
            <p className="subtitlePageProperty">
              Access details
            </p>
          </span>
        </div>

        <FormGroup style={{ marginTop: 20}}>
         {checked.map((value, idx) => (
           <FormControlLabel
            key={idx}
            control={
              <Checkbox
                checked={value.checked}
                onChange={() => toggleCheckbox(value, idx)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={value.value}
          />
         ))}
        </FormGroup>

        {verifyCheck("someone_is_home") ? null : (
          <Input
            placeholder="instructions"
            field="instructions"
            form={step3Form}
            assistiveText={{ color: "#0E1F41" }}
          />
        )}

        <button onClick={step3Form.submitForm} className="buttonNewProperty">NEXT</button>
        <button onClick={() => navigate(-1)} className="buttonNewProperty" style={{ marginTop: '10px'}}>
          GO BACK
        </button>
      </div>
    </AuthorizedLayout>
  );
};

export default Step3;
