import React, { useState, useContext, useEffect } from "react";
import DatePicker from "react-datepicker";
import AppContext from "../../Context/AppContext";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import api from "../../Service/api";
import ManualSchedule from "./Components/ManualSchedule";
import CalendarCard from "../Schedules/components/CalendarCard";
import CalendarImage from "../../Assets/Group 1062.svg";

import "./Schedules.css";

const Schedules = ({ form, onClick, id, property, createSchedule }) => {
  const { user, reloadCard, setReloadCard } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [selectedDaySchedules, setSelectedDaySchedules] = useState([]);
  const [schedules, setSchedules] = useState({});
  const [allSchedules, setAllSchedules] = useState([]);
  const [newSchedule, setNewSchedule] = useState(createSchedule || false);

  useEffect(() => {
    if (schedules && allSchedules?.length > 0) {
      setSelectedDaySchedules(
        allSchedules.filter(
          (item) =>
            item.attributes.date === moment(selectedDay).format("YYYY-MM-DD")
        )
      );
    }
    setReloadCard(false);
  }, [selectedDay, schedules, allSchedules, reloadCard]);

  const scheduleParsing = (sched, day) => {
    if (!day) {
      const scheduleDates = sched.map((s) => s.attributes.date);

      const parsedSchedules = scheduleDates.reduce((acc, date) => {
        return {
          ...acc,
          [date]: {
            marked: true,
            dotColor: "red",
            selected: date === selectedDay ? true : false,
          },
        };
      }, {});
      setSchedules(parsedSchedules);
    } else {
      const scheduleDates = sched.map((s) => s.attributes.date);

      const parsedSchedules = scheduleDates.reduce((acc, date) => {
        return {
          ...acc,
          [date]: {
            marked: true,
            dotColor: "red",
            selected: date === selectedDay ? true : false,
          },
        };
      }, {});

      setSchedules({
        ...parsedSchedules,
        [day]: {
          marked: false,
          dotColor: "red",
          selected: day === selectedDay ? true : false,
        },
      });
    }
  };

  const fetchSchedules = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/addresses/${id}/schedules`);

      const temp = [...data];

      setAllSchedules(temp);

      scheduleParsing(temp, selectedDay);

      setLoading(false);
    } catch (error) {
      //console.log(_error);
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(`${error.response.data.errors[0].title}`);
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSchedules();
  }, []);

  const goBack = () => {
    setSelectedDaySchedules([]);
    setNewSchedule(false);
    fetchSchedules();
  };

  // console.log(allSchedules);

  const checkDate = (data) =>
    allSchedules.some(
      (item) => item.attributes.date === moment(data).format("YYYY-MM-DD")
    );

  return (
    <>
      {newSchedule ? (
        <ManualSchedule
          goBack={goBack}
          price={property?.priceWithDiscount}
          id={property?.cleaningId}
          property={property}
        />
      ) : (
        <>
          <div style={{ maxWidth: "721px", textAlign: "end" }}>
            <button
              className="scheduleButton"
              onClick={() => {
                setNewSchedule(true);
              }}
            >
              SCHEDULE A CLEANING
            </button>
          </div>
          <div className="containerCalendarEdit">
            <DatePicker
              selected={selectedDay}
              onChange={setSelectedDay}
              inline
              dayClassName={(date) => (checkDate(date) ? "random" : undefined)}
              className="red-border"
            />

            <div
              className="containerCardsCalendar"
              style={{ maxWidth: "721px", marginTop: 10 }}
            >
              {selectedDaySchedules.length > 0 && loading === false ? (
                <div className="textDate">{`${moment(selectedDay).format(
                  "MM/DD/YYYY"
                )}`}</div>
              ) : null}
              <div
                className="cards"
                style={{ maxWidth: "721px", marginTop: 10 }}
              >
                {selectedDaySchedules.length > 0 && loading === false ? (
                  selectedDaySchedules.map((sched, key) => (
                    <CalendarCard schedule={sched} key={key} goBack={goBack} />
                  ))
                ) : (
                  <div className="backgroudNoDate">
                    <img src={CalendarImage} alt="React Logo" />
                    <div>Select a day to list the cleanings</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Schedules;
