import React from 'react';
import moment from "moment";
import { trimString } from "../../Utils/trimString";
import GetSinglePdf from './components/GetSinglePdf';
// import { User, Key, Calendar, DollarSign } from 'react-feather';

import './Payment.css';
import { Check } from '@mui/icons-material';

const PaymentCard = ({pay}) => {

  // console.log(pay);

  const checkFee = (name) => {
    switch (name) {
      case 'service':
        return "NORMAL FEE";
      case 'extra':
        return "EXTRA FEE";
      case 'manual':
        return "MANUAL";
      default:
        return "DEEP CLEAN";
    }
  }

  return (
    <>
     <div style={styles.container}>
        <div style={styles.row}>
            
            <div style={styles.value}>
              <div>{`#${pay?.schedule.id}` || "Unavailable"}</div>
            </div>

            <div style={styles.value}>
              <div>{moment(pay?.schedule?.date).format("MMM DD YYYY")}</div>
            </div>

            <div style={styles.valueProperty}>
              <div>{`${pay?.schedule?.address?.id || "Deleted Property"}`}</div>
            </div>

            <div style={styles.value}>
              <div>
                {pay?.schedule?.cleaner?.first_name &&
                pay?.schedule?.cleaner?.last_name
                  ? trimString(
                      `${pay?.schedule?.cleaner?.first_name} ${pay?.schedule?.cleaner?.last_name}`,
                      35
                    )
                  : pay?.schedule?.cleaner ? pay?.schedule?.cleaner : 'Deleted Cleaner'}
              </div>
            </div>

            <div style={styles.value}>
              {checkFee(pay?.chargeType)}
            </div>

            <div style={styles.value}>
              <div>{`${pay?.serviceValue} USD` || "Unavailable"}</div>
            </div>

            {/* <button className="buttonInvoice" onClick={() => GetSinglePdf(pay)}>
              DOWNLOAD INVOICE
            </button> */}
        </div>
      </div>
    </>
  );
}

export default PaymentCard;

const styles = {
  container: {
    // margin: 10,
    marginBottom: 5,
    marginTop: 5,
    padding: "5px 10px",
    borderRadius: 5,
    backgroundColor: "#ffffff",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  icon: {
    marginRight: 6,
  },
  button: {
    marginTop: 20,
  },
  value: {
    display: "flex",
    alignItems: "center",
    // width: "20%",
    borderLeftWidth: 2,
    borderLeftColor: "rgb(85,222,219)",
    textAlign: "center",
    fontSize: "10px",
    fontWeight: "500",
    fontFamily: "Inter, sans-serif",
  },
  valueProperty: {
    display: "flex",
    alignItems: "center",
    width: "20%",
    borderLeftWidth: 2,
    borderLeftColor: "rgb(85,222,219)",
    textAlign: "center",
    justifyContent: "center",
    fontSize: "10px",
    fontWeight: "500",
    fontFamily: "Inter, sans-serif",
  },
};