import React, { useState } from "react";
import Logo from "../../Assets/Group 807.svg";
import Frist from "./Components/Frist";
import LogoAppCleaner from "../../Assets/LogoAppCleaner.svg";
import LogoAppHost from "../../Assets/LogoAppHost.svg";
import background from "../../Assets/LoginBackground.svg";
import { useParams } from "react-router-dom";

import "./LoginGoogleMobile.css";

const LoginGoogleMobile = () => {
  const [screenSlected, setScreenSelected] = useState(true);
  const { id } = useParams();

  return (
    <div className="containerBackgroundLoginGoogleMobile">
      <div className="containerLoginGoogleMobile">
        {id === 'id=true' ? (
          <img src={LogoAppCleaner} alt="logo" className="logoSett" />
        ) : <img src={LogoAppHost} alt="logo" className="logoSett" />}
        <Frist setScreenSelected={setScreenSelected} />
        <div></div>
      </div>
    </div>
  );
};

export default LoginGoogleMobile;
