import React from "react";

import Illustration1 from '../../../../../Assets/image 4 (1).svg';

import './Screen.css';

const Screen3 = () => {
  return (
    <div className="containerCardScren">
      <div className="titleTextOnboard2">Here is your scheduled cleaning calendar</div>
      <img
        src={Illustration1}
        alt="dsa"
        className="img"
        style={{ width: "20vw", marginTop: '2vh' }}
      />
      <div className="suntitleTextOnborad">
        The days you have a scheduled service will appear highlighted in gray
      </div>
    </div>
  );
};

export default Screen3;
