import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import { Typography, Divider, Box } from "@mui/material";
import { Button } from "../../../Components/Button";
import placeholder from "../../../Assets/placeholder.png";
import { useNavigate } from "react-router-dom";
import DifferenceIcon from '@mui/icons-material/Difference';
import { useFormik } from "formik";
import Modal from "@mui/material/Modal";

import "./PropertyCard.css";
import api from "../../../Service/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  // border: '2px solid #000',
  boxShadow: 0,
  p: 0,
};

const PropertyCard = ({ property, allProperty }) => {
  let navigate = useNavigate();

  const { addressNickname, cleanPlacePhotos, address } = property;
  const [opento, setOpento] = useState(false);
  const handleCloseto = () => setOpento(false);
  const [loading, setLoading] = useState(false);

  // console.log(property);

  const cloneProperty = async (values) => {
    const parsedForm = {
      data: {
        attributes: {
          addressNickname: addressNickname || '',
          address: address || '',
          zipCode: property?.zipCode || '',
          city: property?.city || '',
          state: property?.state || '',
          // cleanPlacePhotos: property?.cleanPlacePhotos?.map((p) => ({
          //   data: p.url || '',
          // })) || [],
          getInAttributes: {
            title: property?.getIn.title || "someone_is_home",
            description: property?.getIn.description || "someone_is_home",
          } || [],
          addressChecklistsAttributes: property?.addressChecklists?.map((p) => ({
            description: p?.description || "",
          })) || [],
          cleaningAttributes: {
            bedroomsAmount: property?.cleaning?.bedroomsAmount || '',
            bathroomsAmount: property?.cleaning?.bathroomsAmount || '',
            laundry: property?.cleaning?.laundry || '',
            cleaningOn: property?.cleaning?.cleaningOn || '',
            squareFeet: property?.cleaning?.squareFeet || '',
            consumablesAttributes: property?.cleaning?.consumables?.map((i) => ({
              description: i,
            })) || [],
          //   scheduleAttributes:  {
          //     date: "2023-01-11T11:00:00.907Z",
          //     time: "2023-01-11T11:00:00.907Z",
          //     nextCheckinDate: "2023-01-13T16:00:00.919Z",
          //     nextCheckinTime: "2023-01-13T16:00:00.919Z"
          // }
          },
          // paymentCardId: cardId,
          paymentCardId: [],
          IcalendarUrlsAttributes:  property?.icalendarUrls?.map((l) => ({
            name: l.name,
            url: l.url,
          })) || [],
      },
    }
  }


    try {
      setLoading(true);
      const { data } = await api.post("addresses", parsedForm);

      if (data) {
        navigate("/AllDone");
      }
    } catch (error) {
      // console.log(error);
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(
              `${error.response.data.errors[0].title}`
            );
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
    }
    setLoading(false);
  };


  return (
    <Card sx={{ width: 400, borderRadius: 3, height: 356 }} className="seletor">
      {/* <CardMedia
          component="img"
          alt="Property"
          height="170"
          image={
            cleanPlacePhotos?.length > 0
              ? cleanPlacePhotos[0]
              : placeholder
          }
        /> */}

      <div className="container">
        <button className="DuplicateIcon" onClick={() => setOpento(true)}>
          <DifferenceIcon />
        </button>
      </div>

      <Modal
        open={opento}
        onClose={handleCloseto}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ padding: "15px" }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Duplicate a property
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Want to duplicate the property{" "}
            <strong style={{ textTransform: "uppercase" }}>
              {addressNickname || ""}
            </strong>{" "}
            ?
          </Typography>

          <div className="containerButtonDelete">
            <button
              className="close"
              onClick={() => setOpento(false)}
            >
              CLOSE
            </button>
            <button
              className="delete"
              style={{ backgroundColor: '#f5b21d'}}
              onClick={cloneProperty}
            >
              CLONE
            </button>
          </div>
        </Box>
      </Modal>

      <div
        style={{
          maxWidth: 400,
          height: 170,
          backgroundImage: `url(${
            property.propertyImage ? property.propertyImage.url : placeholder
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <CardContent style={{ padding: 20, paddingBottom: 0 }}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          className="titleCard"
        >
          {addressNickname}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {address || "No address"}
        </Typography>
        <Divider style={{ marginTop: 15 }} />
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // marginBottom: 13,
          padding: 20,
        }}
      >
        <Button
          text={
            property.icalendarUrls.length > 0
              ? "Automated schedule"
              : "Manual schedule"
          }
          primary
          onClick={() =>
            property.icalendarUrls.length > 0
              ? navigate(`/property/${property.id}`, {
                  state: {
                    property: property,
                    iCal: true,
                  },
                })
              : navigate(`/property/${property.id}`, {
                  state: {
                    property: property,
                    editPhoto: true,
                    createSchedule: true,
                  },
                })
          }
        />
        <span>
          <span style={{ margin: "5px" }}>
            <Button
              text="Edit"
              edit
              onClick={() =>
                navigate(`/property/${property.id}`, {
                  state: { property: property,  allProperty: allProperty },
                })
              }
            />
          </span>
          <Button
            text="Cleanings"
            onClick={() =>
              navigate(`/property/${property.id}`, {
                state: { property: property, editPhoto: true },
              })
            }
            edit
          />
        </span>
      </CardActions>
    </Card>
  );
};

export default PropertyCard;
