import moment from "moment";
import { trimString } from "../../../Utils/trimString";
import '../Payment.css';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

const GetPdf = (paymentsFilter) => {

  const checkFee = (name) => {
    switch (name) {
      case 'service':
        return "NORMAL FEE";
      case 'extra':
        return "EXTRA FEE";
      case 'manual':
        return "MANUAL";
      default:
        return "DEEP CLEAN";
    }
  }

  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  const reportTitle = [
    {
      text: "Billing history",
      fontSize: 15,
      bold: true,
      margin: [15,20,0,45]
    }
  ];

  const dados = paymentsFilter.map((pay) => {
    return [
      {
        text: pay?.schedule.id,
        style: "tableHeader",
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
      {
        text: moment(pay?.schedule?.date).format("MMM DD YYYY"),
        style: "tableHeader",
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
      {
        text: `${pay?.schedule?.address?.id || "Deleted Property"}`,
        style: "tableHeader",
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
      {
        text:
          pay?.schedule?.cleaner?.first_name &&
          pay?.schedule?.cleaner?.last_name
            ? trimString(
                `${pay?.schedule?.cleaner?.first_name} ${pay?.schedule?.cleaner?.last_name}`,
                35
              )
            : pay?.schedule?.cleaner ? pay?.schedule?.cleaner : 'Deleted Cleaner',
        style: "tableHeader",
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
      {
        text: checkFee(pay?.chargeType),
        style: "tableHeader",
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
      {
        text: `${pay?.serviceValue} USD` || "Unavailable",
        style: "tableHeader",
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
    ];
  });

  const sumPayments = (number) => {
    var sum = number.reduce(function(accumulator,value){
      return accumulator + Number(value.serviceValue)
    },0);
    return (
      `TOTAL: ${new Intl.NumberFormat("en-us", {
        style: "currency",
        currency: "USD",
      }).format(sum)} USD` || "TOTAL: Unavailable"
    );
  }

  const details = [
    {
      text: `${moment(
        paymentsFilter[paymentsFilter.length - 1].schedule?.date
      ).format("MMM DD YYYY")} to ${moment(
        paymentsFilter[0].schedule?.date
      ).format("MMM DD YYYY")}`,
      style: "textBody",
      alignment: "right",
      fontSize: 9,
      margin: [0, 10, 0, 10],
    },
    {
      table: {
        headerRows: 1,
        widths: ["*", "*", "*", "*", "*", "*"],
        body: [
          [
            { text: "ID", style: "tableHeader", fontSize: 10 },
            { text: "Cleaning Date", style: "tableHeader", fontSize: 10 },
            { text: "Property", style: "tableHeader", fontSize: 10 },
            { text: "Cleaner", style: "tableHeader", fontSize: 10 },
            { text: "Charge type", style: "tableHeader", fontSize: 10 },
            { text: "Cost", style: "tableHeader", fontSize: 10 },
          ],
          ...dados,
        ],
      },
      layout: "lightHorizontalLines",
    },
    {
      text: sumPayments(paymentsFilter),
      style: "textBody",
      alignment: "right",
      bold: true,
      margin: [0,10,0,10]
    },
  ];

  const rodape = (currentPage, pageCount) => {
    return [
      {
        text: `${currentPage} / ${pageCount}`,
        alignment: "right",
        fontSize: 9,
        bold: true,
        margin: [0,10,20,0]
      }
    ]
  };

  const docDefinitios = {
    pageSize: "A4",
    pageMargins: [15, 50, 15, 40],

    header: [reportTitle],
    content: [details],
    footer: rodape,
  }

  pdfMake.createPdf(docDefinitios).download();
}

export default GetPdf;
