import React, { useContext, useState } from "react";
import api from "../../../Service/api";
import * as yup from "yup";
import { useFormik } from "formik";
import { Input } from "../../../Components/Input";
import { InputsContainer } from "../../../Components/InputsContainer";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../Context/AppContext";

// import "./NewProperty.css";

const Edit = () => {
  let navigate = useNavigate();
  const { user, signOut } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const updateClient = async () => {
    setLoading(true);

    try {
      await api.put("update_profile", { data: { attributes: form.values } });

      alert("Update successfull!", "Please log in again.");
      signOut();
    } catch (error) {
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(
              `${error.response.data.errors[0].title}`
            );
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
    }

    setLoading(false);
  };

  const form = useFormik({
    initialValues: {
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      email: user.email,
      phone: user.phone || "",
    },
    validationSchema: yup.object({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().email().required(),
      phone: yup.number().min(10).required(),
    }),
    onSubmit: updateClient,
  });

  return (
    <>
      <div style={{ maxWidth: 721 }}>
        <InputsContainer>
          <Input
            placeholder="First name"
            field="firstName"
            form={form}
            assistiveText={{ color: "#0E1F41" }}
          />

          <Input
            placeholder="Last name"
            field="lastName"
            form={form}
            assistiveText={{ color: "#0E1F41" }}
          />

          <Input
            placeholder="Phone number"
            field="phone"
            form={form}
            keyboardType="number"
            assistiveText={{ color: "#0E1F41" }}
          />

          <Input
            placeholder="Email"
            field="email"
            form={form}
            keyboardType="email"
            assistiveText={{ color: "#0E1F41" }}
          />
        </InputsContainer>

        <button
          onClick={() =>
            navigate(`/ChangePassword`)
          }
          className="buttonNewProperty"
        >
          CHANGE PASSWORD
        </button>

        <button onClick={form.submitForm} className="buttonNewProperty">
          {loading ? "LOADING..." : "SAVE"}
        </button>
      </div>
    </>
  );
};

export default Edit;
