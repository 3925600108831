import React, { useState, useEffect, useContext } from "react";
import api from '../../Service/api';
import AppContext from "../../Context/AppContext";
import placeholder from "../../Assets/placeholder.png";
import Add from "../../Assets/add.svg";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import CardPhoto from './Components/CardPhoto';

import "./EditProperty.css";

const EditProperty = ({ property }) => {
  let navigate = useNavigate();

  const {loadingCoverPhoto, setLoadingCoverPhoto} = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [files, setFile] = useState();

  const form = useFormik({
    initialValues: {
      photos: property?.cleanPlacePhotos
        ? property?.cleanPlacePhotos
        : // property.cleanPlacePhotos.map((image) => {
          //   return {
          //     default: image?.default || false,
          //     description: image?.description || "",
          //     id: image?.id || null,
          //     url: image?.url,
          //   };
          // })
          [],
    },
    onSubmit: () => updatePhotos(),
  });

  useEffect(() => {
    // console.log({
    //   form: {
    //     photos: form.values.photos.map((image) => ({
    //       ...image,
    //       base64: image?.base64?.slice(0, 300) || null,
    //     })),
    //   },
    // });
    if (
      !form.values.photos.find((item) => item.default === true) &&
      form.values.photos.length > 0 &&
      form.values.photos !== property?.cleanPlacePhotos
    ) {
      form.setFieldValue(`photos[0].default`, true);
    }
  }, [form.values.photos.length]);

  // console.log(form.values);

  const updatePhotos = async () => {
    setLoading(true);
    try {
      const { data } = await api.put(`/addresses/${property.id}`, {
        data: {
          attributes: {
            cleanPlacePhotos: form.values.photos.map((p) => {
              if (!p?.id) {
                return {
                  data: p.url,
                  default: p?.default || false,
                  description: p?.description || "",
                };
              } else {
                return {
                  id: p.id,
                  default: p?.default || false,
                  description: p?.description || "",
                };
              }
            }),
          },
        },
      });
      if (data) {
        alert(
          "Clean place photos and instructions are now up to date."
        );
        setLoadingCoverPhoto(true);
        navigate("/Properties");
      }
    } catch (error) {
      // console.log(error);
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(
              `${error.response.data.errors[0].title}`
            );
          } else {
            alert("Something unexpected happened, Please try again.");
          }
        } else {
          alert("Session expired, Please login in again.");
        }
      } else {
        alert("Something unexpected happened, Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const getBase64 = (file) => {
    return new Promise(resolve => {
      let baseURL = "";
      
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const handleFileInputChange = (e) => {
    
    const file = e.target.files[0];

    getBase64(file)
      .then(result => {
        file["base64"] = result;
        // console.log("File Is", file);
        form.setFieldValue("photos", [...form.values.photos, {url: result}]);
      })
      .catch(error => {
        if (error?.response?.status) {
          if (error.response.status !== 401) {
            if (
              error?.response?.data?.errors &&
              error?.response?.data?.errors[0]?.title
            ) {
              alert(
                `${error.response.data.errors[0].title}`
              );
            } else {
              alert("Something unexpected happened.", "Please try again.");
            }
          } else {
            alert("Session expired", "Please login in again.");
          }
        } else {
          alert("Something unexpected happened.", "Please try again.");
        }
      });
      setFile(e.target.files[0])
  };

  const deleteImage = async (imageIndex) => {
    const newSelectedImages = [...form.values.photos];
    newSelectedImages.splice(imageIndex, 1);

    form.setFieldValue("photos", newSelectedImages);
  }

  // console.log(form.values);

  return (
    <div style={{ width: "70%" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span>
          <p className="subtitlePageProperty" style={{ marginTop: "20px" }}>
            Here you can access your last cleanings and help us keep the quality
            of the cleaning.
          </p>
        </span>
      </div>

      <label className="form-group button" htmlFor="fupload">
        <img src={Add} alt="Add" />
        <label className="control-label label-bordered" htmlFor="fupload">
          ADD IMAGES
        </label>
        <input
          type="file"
          id="fupload"
          name="fupload"
          className="fupload form-control"
          accept=".jpeg, .png, .jpg"
          onChange={handleFileInputChange}
        />
      </label>

      <div className="containerAllCardsPhotos">
        {form.values.photos.length > 0
          ? form.values.photos.map((value, idx) => (
              <CardPhoto
                photo={value.url}
                onClick={() => deleteImage(idx)}
                form={form}
                id={idx}
                key={idx}
                description={value.description || ''}
              />
            ))
          : null}
      </div>

      <button onClick={form.submitForm} className="buttonSaveProperty">
        {loading ? "SAVING..." : "SAVE"}
      </button>
    </div>
  );
};

export default EditProperty;
