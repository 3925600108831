import React, { useState, useContext } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import AppContext from "../../../Context/AppContext";
import Logo from "../../../Assets/Group 807.svg";
import IconGOogle from "../../../Assets/Icon Google.svg";
import GoogleIcon from "@mui/icons-material/Google";
import * as yup from "yup";

const Frist = () => {
  const { signIn, loginGoogle, loadingButtonogin, setLoadingButtonogin } = useContext(AppContext);

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid email format.")
        .required("Email is required!"),
      password: yup
        .string()
        .min(8, "Password must contain at least 8 characters.")
        .required("Password required!"),
    }),

    onSubmit: (values) => handleContinue(values),
  });

  const handleContinue = async (values) => {
    const { email, password } = values;
    try {
      setLoadingButtonogin(true);
      await signIn({ email: email, password: password });
      setLoadingButtonogin(false);
    } catch (error) {
      //console.log(_error);
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(`${error.response.data.errors[0].title}`);
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
    }
    setLoadingButtonogin(false);
  };

  return (
    <>
      <button className="google-button" onClick={loginGoogle}>
        <img src={IconGOogle} alt="Add" className="google-logo" />
        Continue with Google
      </button>

      {/* <div className="containerRegister">

          <div className="containerSign">
            <div>Don't have an account yet? </div>
            <a
              // style={{ color: "black" }}
              href="/SignUp"
            >
              Sign up now!
            </a>
          </div>
        </div> */}

      <div className="containerLines">
        <div className="line"></div>
        <div className="text">or continue with email</div>
        <div className="line"></div>
      </div>

      <div className="containerInputs">
        <div className="errorINputRegi">
          <div
            style={{
              color: "#ffffff",
              marginBottom: "6px",
              fontSize: "13px",
              marginLeft: '5px'
            }}
          >
            E-mail
          </div>
          <input
            className="inputLogin"
            placeholder={"E-mail"}
            style={{textIndent: '10px'}}
            type={"email"}
            onChange={(e) => {
              form.setFieldValue("email", e.target.value);
            }}
            onBlur={() => {
              form.setFieldTouched("email", true);
            }}
          />
          {form.touched.email && form.errors.email && (
            <Typography
              style={{ fontSize: 14, marginLeft: 5, marginTop: 5 }}
              color={"red"}
            >
              {form.errors.email}
            </Typography>
          )}
        </div>

        <div className="errorINputRegi">
          <div
            style={{
              color: "#ffffff",
              marginBottom: "6px",
              fontSize: "13px",
              marginLeft: '5px'
            }}
          >
            Password
          </div>
          <input
            style={{textIndent: '10px'}}
            className="inputLogin"
            placeholder={"Password"}
            type={"password"}
            onChange={(e) => {
              form.setFieldValue("password", e.target.value);
            }}
            onBlur={() => {
              form.setFieldTouched("password", true);
            }}
          />
          {form.touched.password && form.errors.password && (
            <Typography
              style={{ fontSize: 14, marginLeft: 5, marginTop: 5 }}
              color={"red"}
            >
              {form.errors.password}
            </Typography>
          )}
        </div>

        <div className="containerLoginForgot">
          <button
            type="submit"
            onClick={() => {
              form.handleSubmit();
            }}
            className="buttonLogin"
          >
            {loadingButtonogin ? "Loading..." : "Log In"}
          </button>

          <a
            href="/ForgotPassword"
            style={{
              color: "#ffffff",
              marginTop: "15px",
              fontSize: "12px",
              textDecoration: "none",
            }}
          >
            Forgot your password?
          </a>
        </div>
      </div>
    </>
  );
};

export default Frist;
