import React, { useState, useContext } from "react";
import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import AppContext from "../../../Context/AppContext";
import IconGOogle from "../../../Assets/Icon Google.svg";
import * as yup from "yup";
import Modal from "@mui/material/Modal";
import { Terms } from "./Terms";
import api from "../../../Service/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  // border: '2px solid #000',
  boxShadow: 0,
  p: 0,
};

const SignUp = ({ setScreenSelected }) => {
  const { loginGoogle } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      passwordConfirmation: "",
      password: "",
      acceptTerms: false,
    },
    validationSchema: yup.object({
      firstName: yup.string().required("First Name is required!"),
      lastName: yup.string().required("Last Name is required!"),
      email: yup
        .string()
        .email("Invalid email format.")
        .required("Email is required!"),
      phone: yup
        .string()
        .min(8, "Phone must contain at least 8 numbers.")
        .required("Phone required!"),
      password: yup
        .string()
        .min(8, "Password must contain at least 8 characters.")
        .required("Password required!"),
      passwordConfirmation: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Password Confirmation required!"),
      acceptTerms: yup
        .boolean()
        .isTrue("please check this box if you want to proceed."),
    }),

    onSubmit: (values) => handleContinue(values),
  });

  // console.log(form.values);

  const handleContinue = async (values) => {
    const parsedForm = {
      data: {
        attributes: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          password: values.password,
          passwordConfirmation: values.passwordConfirmation,
          acceptTerms: values.acceptTerms,
        },
      },
    };

    try {
      setLoading(true);
      await api.post("sign_up", parsedForm);
      setLoading(false);
      alert(
        "Thanks for signing up. Please confirm your email address, to activate your account."
      );
      setScreenSelected(true);
    } catch (error) {
      // console.log(error);
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(`${error.response.data.errors[0].title}`);
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="containeModal" style={{ padding: 20 }}>
          <Terms />
        </Box>
      </Modal>
      <div className="containerInputs">
        <div style={{ display: "flex", gap: "20px" }}>
          <div className="errorINputRegi">
            <div
              style={{
                color: "#ffffff",
                marginBottom: "6px",
                fontSize: "13px",
                marginLeft: '5px'
              }}
            >
              First Name
            </div>
            <input
              className="inputLogin"
              style={{textIndent: '10px'}}
              placeholder={"First Name"}
              type={"text"}
              onChange={(e) => {
                form.setFieldValue("firstName", e.target.value);
              }}
              onBlur={() => {
                form.setFieldTouched("firstName", true);
              }}
            />
            {form.touched.firstName && form.errors.firstName && (
              <Typography
                style={{ fontSize: 14, marginLeft: 5, marginTop: 3 }}
                color={"red"}
              >
                {form.errors.firstName}
              </Typography>
            )}
          </div>

          <div className="errorINputRegi">
            <div
              style={{
                color: "#ffffff",
                marginBottom: "6px",
                fontSize: "13px",
                marginLeft: '5px'
              }}
            >
              Last Name
            </div>
            <input
              className="inputLogin"
              style={{textIndent: '10px'}}
              placeholder={"Last Name"}
              type={"text"}
              onChange={(e) => {
                form.setFieldValue("lastName", e.target.value);
              }}
              onBlur={() => {
                form.setFieldTouched("lastName", true);
              }}
            />
            {form.touched.lastName && form.errors.lastName && (
              <Typography
                style={{ fontSize: 14, marginLeft: 5, marginTop: 3 }}
                color={"red"}
              >
                {form.errors.lastName}
              </Typography>
            )}
          </div>
        </div>

        <div className="errorINputRegi">
          <div
            style={{
              color: "#ffffff",
              marginBottom: "6px",
              fontSize: "13px",
              marginLeft: '5px'
            }}
          >
            Phone
          </div>
          <input
            className="inputLogin"
            style={{textIndent: '10px'}}
            placeholder={"Phone"}
            type={"number"}
            onChange={(e) => {
              form.setFieldValue("phone", e.target.value);
            }}
            onBlur={() => {
              form.setFieldTouched("phone", true);
            }}
          />
          {form.touched.phone && form.errors.phone && (
            <Typography
              style={{ fontSize: 14, marginLeft: 5, marginTop: 3 }}
              color={"red"}
            >
              {form.errors.phone}
            </Typography>
          )}
        </div>

        <div className="errorINputRegi">
          <div
            style={{
              color: "#ffffff",
              marginBottom: "6px",
              fontSize: "13px",
              marginLeft: '5px'
            }}
          >
            E-mail
          </div>
          <input
            className="inputLogin"
            style={{textIndent: '10px'}}
            placeholder={"E-mail"}
            type={"email"}
            onChange={(e) => {
              form.setFieldValue("email", e.target.value);
            }}
            onBlur={() => {
              form.setFieldTouched("email", true);
            }}
          />
          {form.touched.email && form.errors.email && (
            <Typography
              style={{ fontSize: 14, marginLeft: 5, marginTop: 3 }}
              color={"red"}
            >
              {form.errors.email}
            </Typography>
          )}
        </div>

        <div className="errorINputRegi">
          <div
            style={{
              color: "#ffffff",
              marginBottom: "6px",
              fontSize: "13px",
              marginLeft: '5px'
            }}
          >
            Password
          </div>
          <input
            className="inputLogin"
            style={{textIndent: '10px'}}
            placeholder={"Password"}
            type={"password"}
            onChange={(e) => {
              form.setFieldValue("password", e.target.value);
            }}
            onBlur={() => {
              form.setFieldTouched("password", true);
            }}
          />
          {form.touched.password && form.errors.password && (
            <Typography
              style={{ fontSize: 14, marginLeft: 5, marginTop: 3 }}
              color={"red"}
            >
              {form.errors.password}
            </Typography>
          )}
        </div>

        <div className="errorINputRegi">
          <div
            style={{
              color: "#ffffff",
              marginBottom: "6px",
              fontSize: "13px",
              marginLeft: '5px'
            }}
          >
            Password Confirmation
          </div>
          <input
            className="inputLogin"
            style={{textIndent: '10px'}}
            placeholder={"Password confirmation"}
            type={"password"}
            onChange={(e) => {
              form.setFieldValue("passwordConfirmation", e.target.value);
            }}
            onBlur={() => {
              form.setFieldTouched("passwordConfirmation", true);
            }}
          />
          {form.touched.passwordConfirmation &&
            form.errors.passwordConfirmation && (
              <Typography
                style={{ fontSize: 14, marginLeft: 5, marginTop: 3 }}
                color={"red"}
              >
                {form.errors.passwordConfirmation}
              </Typography>
            )}
        </div>

        <div
          className="containerTermsRegister"
          // style={{ alignItems: "center", marginTop: 0, display: 'flex' }}
        >
          <div style={{ display: "flex", gap : '10px', cursor: 'pointer' }}>
            <div style={{ display: "flex", cursor: 'pointer' }}>
              <input
                className="inputLogin"
                style={{textIndent: '10px', width: '20px'}}
                placeholder={"Password confirmation"}
                type={"checkbox"}
                onChange={(e) => {
                  form.setFieldValue("acceptTerms", !form.values.acceptTerms);
                }}
                onBlur={() => {
                  form.setFieldTouched("acceptTerms", form.values.acceptTerms);
                }}
              />
            </div>
            <button onClick={handleOpen} className="buttonTerm" style={{ cursor :'pointer'}}>
              I agree to the Terms os Service and Privacy Policy.
            </button>
          </div>

          <button
            type="submit"
            className="buttonRegister"
            onClick={() => {
              form.handleSubmit();
            }}
            style={{ cursor :'pointer'}}
          >
            {loading ? "Registering.." : "Register"}
          </button>
        </div>

        {form.touched.acceptTerms && form.errors.acceptTerms && (
          <Typography
            style={{ fontSize: 14, marginLeft: 5, marginTop: 0 }}
            color={"red"}
          >
            {form.errors.acceptTerms}
          </Typography>
        )}
      </div>

      <div className="containerLines">
        <div className="line" style={{ width: "42%" }}></div>
        <div className="text" style={{ fontSize: "15px" }}>
          OR
        </div>
        <div className="line" style={{ width: "42%" }}></div>
      </div>

      <button className="google-button" onClick={loginGoogle}>
        <img src={IconGOogle} alt="Add" className="google-logo" />
        Continue with Google
      </button>

      <div className="containerInputs">
        <div className="containerLoginForgot">
          <button
            onClick={() => setScreenSelected(true)}
            style={{
              color: "#ffffff",
              fontSize: "12px",
              textDecoration: "none",
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
            }}
          >
            Already have an account?{" "}
            <span style={{ color: "#75cbee" }}>Login</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default SignUp;
