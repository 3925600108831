import React, { useState, useContext } from "react";
import { Divider, CardMedia, CardContent, Card, Box } from "@mui/material";
import moment from "moment";
import ImageViewer from "react-simple-image-viewer";
import placeholder from "../../../Assets/placeholder.png";
import { Button } from "../../../Components/Button";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import clsx from "clsx";
import api from "../../../Service/api";

import alert from "../../../Assets/alert-triangle.svg";
import clipboard from "../../../Assets/clipboard.svg";
import user2 from "../../../Assets/user2.svg";
import check from "../../../Assets/check.svg";
import settings from "../../../Assets/settings.svg";
import clock from "../../../Assets/clock.svg";
import { DollarSign } from "react-feather";
import Trash from "../../../Assets/trash-2.svg";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Edit from "@mui/icons-material/Edit";
import "./CardScheduleDetail.css";
import ManualSchedule from "./ManualSchedule";
import ExtendSchedule from "./ExtendSchedule";
import ImageIcon from "@mui/icons-material/Image";

import ScheduleReview from "./Review/ScheduleReview";

const BackdropUnstyled = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

BackdropUnstyled.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  // border: '2px solid #000',
  boxShadow: 0,
  p: 0,
};

export default function CardScheduleDetail({ schedule, id, goBack }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [opento, setOpento] = useState(false);
  const handleCloseto = () => setOpen(false);
  const [opentEdit, setOpentEdit] = useState(false);
  const handleCloseEdit = () => setOpentEdit(false);
  const [openEx, setOpenEx] = useState(false);
  const handleOpenEx = () => setOpenEx(true);
  const handleCloseEx = () => setOpenEx(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [disabledReview, setDisabledReview] = useState(false);

  const { attributes } = schedule;

  const [currentImage, setCurrentImage] = useState(0);

  const [viewerClean, setViewerClean] = useState(false);

  const openImageViewerClean = (index) => {
    setCurrentImage(index);
    setViewerClean(true);
  };

  const closeImageViewerClean = () => {
    setCurrentImage(0);
    setViewerClean(false);
  };

  const [viewerBroken, setIsViewerBroken] = useState(false);

  const openImageViewerBroken = (index) => {
    setCurrentImage(index);
    setIsViewerBroken(true);
  };

  const closeImageViewerBroken = () => {
    setCurrentImage(0);
    setIsViewerBroken(false);
  };

  const [viewerDeep, setIsViewerDeep] = useState(false);

  const openImageViewerDeep = (index) => {
    setCurrentImage(index);
    setIsViewerDeep(true);
  };

  const closeImageViewerDeep = () => {
    setCurrentImage(0);
    setIsViewerDeep(false);
  };

  let navigate = useNavigate();

  const deteleCleaner = async () => {
    setReloadPage(true);
    try {
      await api.post(`/schedules/${id}/cancel/`);
      setReloadPage(false);
      // setOpento(false);
      // setOpen(false);
      goBack();
    } catch (error) {
      console.log(error);
      setReloadPage(false);
    }
  };

  // console.log(schedule);

  const capitalize = (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.substr(1);
  };

  return (
    <div>
      <Button
        text="More details"
        edit
        style={{ height: 30, width: "200px", marginRight: 10, display: "flex" }}
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{ zIndex: 10 }}
      >
        <Box sx={style}>
          <Card
            sx={{ width: 400, borderRadius: "10px", overflow: "auto" }}
            className="containeModal"
          >
            {schedule?.attributes?.extended && (
              <div className="extended">Extended</div>
            )}
            <CardMedia
              component="img"
              height="170px"
              image={
                attributes.cleaning.property_image
                  ? attributes.cleaning.property_image.url
                  : placeholder
              }
              alt="green iguana"
            />
            <CardContent className="containerCardDetailSchedule">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="containerTitleDetailSchu">
                  <div className="titleCard">
                    {attributes.address?.address_nickname || "No nickname"}
                  </div>
                  <div className="sutitleCard" style={{ margin: 0 }}>
                    {`${attributes.address?.city} - ${attributes.address?.state}` ||
                      "No Address"}
                  </div>
                </div>
                {schedule?.attributes?.status === "done" ? (
                  ""
                ) : (
                  <button
                    className="deleteCleaningButton"
                    onClick={() => setOpentEdit(true)}
                  >
                    <Edit
                      // src={Trash}
                      className="trashButton"
                      alt="trash"
                      sx={{ color: "white" }}
                      style={{
                        width: "23px",
                        height: "23px",
                        backgroundColor: "#f5b21d",
                        padding: "5px",
                        borderRadius: "5px",
                        border: "none",
                      }}
                    />
                  </button>
                )}

                <Modal
                  open={opentEdit}
                  onClose={handleCloseEdit}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} style={{ padding: "15px" }}>
                    <ManualSchedule
                      goBack={goBack}
                      // price={property?.priceWithDiscount}
                      id={schedule?.id}
                      property={attributes}
                      onClick={() => setOpentEdit(false)}
                    />

                    {/* <div className="containerButtonDelete">
                      <button className="close" onClick={() => setOpentEdit(false)}>
                        CLOSE
                      </button>
                      <button
                        className="delete"
                        onClick={() => deteleCleaner()}
                      >
                        {reloadCard ? "DELETING..." : "DELETE"}
                      </button>
                    </div> */}
                  </Box>
                </Modal>

                {schedule?.attributes?.status === "done" ? (
                  ""
                ) : (
                  <button
                    className="deleteCleaningButton"
                    onClick={() => setOpento(true)}
                  >
                    <img
                      src={Trash}
                      className="trashButton"
                      alt="trash"
                      style={{
                        width: "23px",
                        height: "23px",
                        backgroundColor: "red",
                        padding: "5px",
                        borderRadius: "5px",
                        border: "none",
                      }}
                    />
                  </button>
                )}

                <Modal
                  open={opento}
                  onClose={handleCloseto}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} style={{ padding: "15px" }}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Delete cleaning
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Do you want to delete this cleanup{" "}
                      <strong style={{ textTransform: "uppercase" }}>
                        {attributes.address?.address_nickname || "No nickname"}
                      </strong>{" "}
                      ?
                    </Typography>

                    <div className="containerButtonDelete">
                      <button
                        className="close"
                        onClick={() => setOpento(false)}
                      >
                        CLOSE
                      </button>
                      <button
                        className="delete"
                        onClick={() => deteleCleaner()}
                      >
                        {reloadPage ? "DELETING..." : "DELETE"}
                      </button>
                    </div>
                  </Box>
                </Modal>

                {schedule?.attributes?.status === "done" ? (
                  ""
                ) : (
                  <button
                    onClick={() => setOpenEx(true)}
                    style={{
                      width: "70PX",
                      height: "32px",
                      backgroundColor: "#1d4e92",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "none",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginBottom: "2px",
                    }}
                  >
                    EXTEND
                  </button>
                )}

                <Modal
                  open={openEx}
                  onClose={handleCloseEdit}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} style={{ padding: "15px" }}>
                    <ExtendSchedule
                      goBack={goBack}
                      // price={property?.priceWithDiscount}
                      id={schedule?.id}
                      property={attributes}
                      onClick={() => setOpenEx(false)}
                    />
                  </Box>
                </Modal>
              </div>

              <Divider sx={{ border: "1px solid #A9B2C2", margin: "15px 0" }} />

              <div className="titleCard">More details</div>
              <div className="sutitleCard" style={{ marginTop: "5px" }}>
                <img src={clock} alt="icon_image" />
                {`${moment(attributes.date).format("MM/DD/YY")} • ${
                  attributes.time
                }`}
              </div>

              <div className="sutitleCard">
                <DollarSign
                  name="dollar-sign"
                  size={20}
                  color={"#1D4E92"}
                  style={{ marginRight: "3px" }}
                />
                {Number(attributes.priceWithDiscount).toFixed(2)}
              </div>

              <div className="sutitleCard">
                <img src={settings} alt="icon_image" />
                {`${capitalize(attributes.kind)} Cleaning`}
              </div>
              <div className="sutitleCard">
                <img src={check} alt="icon_image" />
                {capitalize(attributes.status)}
              </div>
              <div className="sutitleCard">
                <img src={user2} alt="icon_image" />
                {attributes.cleaner || "Deleted Cleaner"}
              </div>

              {schedule.attributes.status === "done" && (
                <>
                  <Divider
                    sx={{ border: "1px solid #A9B2C2", margin: "15px 0" }}
                  />

                  <div className="titleCard">Cleaning Report</div>
                  {schedule.attributes.cleanerReview?.nps && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div
                          className="sutitleCard"
                          style={{ marginRight: 10 }}
                        >
                          <img src={clipboard} alt="icon_image" />
                          Cleaner Review
                        </div>

                        <Box
                          sx={{
                            //marginLeft: "10px",
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            //textAlign: "flex-start",
                            paddingLeft: "25px",
                            width: "55%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: attributes.cleanerReview?.condition
                                ? "green"
                                : "red",
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "400",
                                marginRight: "5px",
                                width: "15px",
                                textAlign: "center",
                              }}
                            >
                              {attributes.cleanerReview?.condition ? "+" : "-"}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "400",
                              }}
                            >
                              {attributes.cleanerReview?.condition
                                ? "House in good condition"
                                : "House in poor condition"}
                            </Typography>
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: attributes.cleanerReview?.smell
                                ? "green"
                                : "red",
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "400",
                                marginRight: "5px",
                                width: "15px",
                                textAlign: "center",
                              }}
                            >
                              {attributes.cleanerReview?.smell ? "+" : "-"}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "400",
                              }}
                            >
                              {attributes.cleanerReview?.smell
                                ? "House smelling nicely"
                                : "House smelling weird"}
                            </Typography>
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: attributes.cleanerReview?.guest
                                ? "green"
                                : "red",
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "400",
                                marginRight: "5px",
                                width: "15px",
                                textAlign: "center",
                              }}
                            >
                              {attributes.cleanerReview?.guest ? "+" : "-"}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "400",
                              }}
                            >
                              {attributes.cleanerReview?.guest
                                ? "Good guest"
                                : "Bad guest"}
                            </Typography>
                          </Typography>

                          {/* <Rating
                            name="text-feedback"
                            size="small"
                            value={attributes.cleanerReview?.nps || 5}
                            style={{ color: "#7CC8E4" }}
                            readOnly
                            precision={0.5}
                            emptyIcon={
                              <StarIcon
                                style={{ color: "#0E1F41" }}
                                fontSize="inherit"
                              />
                            }
                          /> */}
                        </Box>
                      </div>
                    </>
                  )}

                  <div className="sutitleCard">
                    <img src={alert} alt="icon_image" />
                    {attributes.cleanerReview?.brokenObjects === true
                      ? attributes.cleanerReview.incidentDescription
                      : "No damaged objects"}
                  </div>

                  {attributes?.standardConsumablesToRestock.length > 0 && (
                    <div className="sutitleCard" style={{ flexDirection :'column', alignItems: 'flex-start', marginTop: '8px'}}>
                        <strong>Consumables to Restock:</strong>
                       <div>
                       {attributes?.standardConsumablesToRestock.map((item, idx) => (
                          <div style={{color: 'red'}}>{`- ${item.description}`}</div>
                        ))}
                       </div>
                    </div>
                  )}

                  {attributes.cleanerReview?.brokenObjects === true &&
                    attributes.cleanerReview?.objectPhotos?.length > 0 && (
                      <>
                        <Divider
                          sx={{ border: "1px solid #A9B2C2", margin: "15px 0" }}
                        />
                        <div>
                          <div style={{ display: "flex", marginBottom: 20 }}>
                            <ImageIcon
                              sx={{ color: "#2b5999" }}
                              style={{
                                width: "20px",
                                height: "20px",
                                border: "none",
                                marginRight: 3,
                                marginLeft: 10,
                              }}
                            />
                            <div className="titleCard">
                              Damaged Object Photos
                            </div>
                          </div>
                          {attributes.cleanerReview?.objectPhotos.map(
                            (image, i) => (
                              <div
                                key={i}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={`${image}`}
                                  style={{ width: "320px", marginBottom: 10 }}
                                  alt="imag"
                                  onClick={() => openImageViewerBroken(i)}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </>
                    )}

                  {attributes.cleanerReview?.cleanPlacePhotos?.length > 0 && (
                    <>
                      <Divider
                        sx={{ border: "1px solid #A9B2C2", margin: "15px 0" }}
                      />
                      <div>
                        <div style={{ display: "flex", marginBottom: 20 }}>
                          <ImageIcon
                            sx={{ color: "#2b5999" }}
                            style={{
                              width: "20px",
                              height: "20px",
                              border: "none",
                              marginRight: 3,
                              marginLeft: 10,
                            }}
                          />
                          <div className="titleCard">Clean Place Photos</div>
                        </div>
                        {attributes.cleanerReview?.cleanPlacePhotos.map(
                          (image, i) => (
                            <div
                              key={i}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={`${image}`}
                                style={{ width: "320px", marginBottom: 10 }}
                                alt="imag"
                                onClick={() => openImageViewerClean(i)}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </>
                  )}

                  {attributes.cleanerReview?.deepCleaning &&
                    attributes.cleanerReview?.deepCleanPhotos?.length > 0 && (
                      <>
                        <Divider
                          sx={{ border: "1px solid #A9B2C2", margin: "15px 0" }}
                        />
                        <div>
                          <div style={{ display: "flex", marginBottom: 20 }}>
                            <ImageIcon
                              sx={{ color: "#2b5999" }}
                              style={{
                                width: "20px",
                                height: "20px",
                                border: "none",
                                marginRight: 3,
                                marginLeft: 10,
                              }}
                            />
                            <div className="titleCard">
                              Deep Cleaning Photos
                            </div>
                          </div>
                          {attributes.cleanerReview?.deepCleanPhotos.map(
                            (image, i) => (
                              <div
                                key={i}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={`${image}`}
                                  style={{ width: "320px", marginBottom: 10 }}
                                  alt="imag"
                                  onClick={() => openImageViewerDeep(i)}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </>
                    )}
                  <div style={{ marginTop: "15px" }}>
                    {/* {console.log(schedule)} */}
                    <ScheduleReview
                      schedule={{ id: schedule.id, ...schedule.attributes }}
                      disabled={
                        schedule?.attributes?.clientReview || disabledReview
                      }
                      setDisabledReview={setDisabledReview}
                    />
                  </div>
                </>
              )}
            </CardContent>
          </Card>
        </Box>
      </Modal>

      <Modal
        open={viewerClean || viewerBroken || viewerDeep}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className="imageModal" style={{ flex: 1, height: "auto" }}>
          {viewerBroken && (
            <ImageViewer
              src={attributes.cleanerReview?.objectPhotos.map(
                (image, i) => `${image}`
              )}
              currentIndex={currentImage}
              disableScroll={true}
              closeOnClickOutside={true}
              onClose={closeImageViewerBroken}
            />
          )}
          {viewerClean && (
            <ImageViewer
              src={attributes.cleanerReview?.cleanPlacePhotos.map(
                (image, i) => `${image}`
              )}
              currentIndex={currentImage}
              disableScroll={true}
              closeOnClickOutside={true}
              onClose={closeImageViewerClean}
            />
          )}
          {viewerDeep && (
            <ImageViewer
              src={attributes.cleanerReview?.deepCleanPhotos.map(
                (image, i) => `${image}`
              )}
              currentIndex={currentImage}
              disableScroll={true}
              closeOnClickOutside={true}
              onClose={closeImageViewerDeep}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}
