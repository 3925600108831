import React, { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import NewPropertyContext from "./NewPropertyContext";
import api from "../Service/api";
import { useFormik } from "formik";
import moment from "moment";
import AppContext from "./AppContext";

function NewPropertyProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const { totalProperties } = useContext(AppContext);

  let navigate = useNavigate();

  const step1Form = useFormik({
    initialValues: {
      bedrooms: "",
      bathrooms: "",
      squareFeet: "",
      laundry: false,
      other: "",
    },
    validationSchema: yup.object({
      bedrooms: yup.number().integer().min(0).required(),
      bathrooms: yup.number().positive().required(),
      squareFeet: yup.number().positive().required(),
    }),
    onSubmit: () => navigate("/step2"),
  });

  const step2Form = useFormik({
    initialValues: {
      nickname: "",
      address: "",
      zipCode: "",
      state: "",
      city: "",
    },
    validationSchema: yup.object({
      nickname: yup.string().required(),
      address: yup.string().min(5).required(),
      zipCode: yup.string().min(5).required(),
      state: yup.string().min(2).max(2).required(),
      city: yup.string().required(),
    }),
    onSubmit: () => navigate("/step3"),
  });

  const step3Form = useFormik({
    initialValues: {
      way: "someone_is_home",
      instructions: "",
    },
    validate: (values) => {
      const errors = {};

      if (values.way === "doorcode" && values.instructions === "") {
        errors.instructions = "Please specify the doorcode";
      }

      if (values.way === "hidden_key" && values.instructions === "") {
        errors.instructions = "Please specify where is the key";
      }

      if (values.way === "other" && values.instructions === "") {
        errors.instructions = "Please specify how to get in";
      }

      return errors;
    },
    onSubmit: () => navigate("/step4"),
  });

  // console.log(step3Form.values);

  const step4Form = useFormik({
    initialValues: {
      checklist: [],
    },
    onSubmit: () => navigate("/step5"),
  });

  const step5Form = useFormik({
    initialValues: {
      consumables: [],
    },
    onSubmit: () => navigate("/step6"),
  });

  const step6Form = useFormik({
    initialValues: {
      photos: [],
    },
    onSubmit: () => navigate("/step7"),
  });

  const step7Form = useFormik({
    initialValues: {
      iCalLinks: [],
      cleaningOn: true,
      checkiSchedule: false,
      id: null,
      code: "",
      percent: 0,
      amount: 0,
      expirationDate: "",
      errorText: "",
      checkoutDate: new Date(
        new Date(new Date(new Date().setHours(11)).setMinutes(0)).setSeconds(0)
      ),
      checkinDate: new Date(
        new Date(new Date(new Date().setHours(16)).setMinutes(0)).setSeconds(0)
      ),
    },
    // validate: (values) => {
    //   const errors = {};

    //   if (values.iCalLinks.length <= 0) {
    //     errors.instructions = "Please add at least one ical link";
    //   }
    //   return errors;
    // },

    validate: (values) => {
      const errors = {};

      if (values.checkinDate < values.checkoutDate) {
        alert("The check-in date can never be less/before the check-out date");
      }
      return errors;
    },

    onSubmit: () => createProperty(),
  });

  // console.log(step7Form.errors);

  const hasIcalLinks = step7Form.values.iCalLinks.length > 0;

  // console.log(step3Form.values);

  const cleaningAttributes = step7Form.values.checkiSchedule
    ? {
        bedroomsAmount: step1Form.values.bedrooms,
        bathroomsAmount: step1Form.values.bathrooms,
        laundry: step1Form.values.laundry,
        cleaningOn: step7Form.cleaningOn ? "check_out" : "check_in",
        squareFeet: step1Form.values.squareFeet,
        consumablesAttributes: step5Form.values.consumables.map((i) => ({
          description: i,
        })),
      }
    : {
        bedroomsAmount: step1Form.values.bedrooms,
        bathroomsAmount: step1Form.values.bathrooms,
        laundry: step1Form.values.laundry,
        cleaningOn: step7Form.cleaningOn ? "check_out" : "check_in",
        squareFeet: step1Form.values.squareFeet,
        consumablesAttributes: step5Form.values.consumables.map((i) => ({
          description: i,
        })),
        scheduleAttributes: {
          date: moment(step7Form.values.checkoutDate).format("YYYY-MM-DD"),
          time: `${moment(step7Form.values.checkoutDate).format("HH:mm")}`,
          nextCheckinDate: moment(step7Form.values.checkinDate).format(
            "YYYY-MM-DD"
          ),
          nextCheckinTime: `${moment(step7Form.values.checkinDate).format(
            "HH:mm"
          )}`,
          cleaningCouponId:
            step7Form?.values?.iCalLinks?.length > 0
              ? undefined
              : step7Form?.values?.id || null,
        },
      };

  // console.log(cleaningAttributes);

  const createProperty = async () => {
    const parsedForm = {
      data: {
        attributes: {
          addressNickname: step2Form.values.nickname,
          address: step2Form.values.address,
          zipCode: step2Form.values.zipCode,
          city: step2Form.values.city,
          state: step2Form.values.state,
          cleanPlacePhotos: step6Form.values.photos.map((p) => ({
            data: p.data[0],
            default: p?.default || false,
            description: p?.description || "",
          })),
          getInAttributes: {
            title: step3Form.values.way
              ? step3Form.values.way
              : "someone_is_home",
            description: step3Form.values.instructions
              ? step3Form.values.instructions
              : "someone_is_home",
          },
          addressChecklistsAttributes: step4Form.values.checklist.map((i) => ({
            description: i,
          })),
          cleaningAttributes,
          // paymentCardId: cardId,
          paymentCardId: [],
          IcalendarUrlsAttributes:
            step7Form?.values?.iCalLinks?.length > 0
              ? step7Form.values.iCalLinks.map((l) => ({
                  name: l.name,
                  url: l.url,
                }))
              : [],
        },
      },
    };

    try {
      setLoading(true);
      const { data } = await api.post("addresses", parsedForm);

      if (data) {
        if (totalProperties <= 0) {
          alert(
            "Hi, Thank you for partnering with us to elevate your guest experience! Our City Manager will be contacting you shortly for a welcoming call!"
          );
        }
        navigate("/AllDone");
      }

      step7Form.setFieldValue(
        "checkiSchedule",
        !step7Form.values.checkiSchedule
      );
    } catch (error) {
      // console.log(parsedForm);
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(`${error.response.data.errors[0].title}`);
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
    }
    setLoading(false);
  };

  return (
    <NewPropertyContext.Provider
      value={{
        step1Form,
        step2Form,
        step3Form,
        step4Form,
        step5Form,
        step6Form,
        step7Form,
        createProperty,
      }}
    >
      {children}
    </NewPropertyContext.Provider>
  );
}

export default NewPropertyProvider;
