import React, { useState, useEffect } from "react";
import AppContext from "./AppContext";
import api from "../Service/api";
import moment from "moment";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

function AppProvider({ children }) {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reloadPage, setRealoadPage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [loadingCoverPhoto, setLoadingCoverPhoto] = useState(false);
  const [reloadCard, setReloadCard] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [totalProperties, setTotalProperties] = useState(0);
  const [loadingButtonogin, setLoadingButtonogin] = useState(false);

  const signInGoogle = async (token) => {
    setLoadingButtonogin(true);

    try {
      const { data } = await axios.post(
        "https://api.sett.solutions/auth/google_oauth2/callback"
        //"https://settcorp-api.herokuapp.com/auth/google_oauth2/callback"
        ,
        {
          code: token.code,
          user: "client",
        }
      );

      const parsedUser = {
        id: data.client.data.id,
        ...data.client.data.attributes,
      };

      localStorage.setItem("@SettcorpClient: user", JSON.stringify(parsedUser));

      api.defaults.headers["Authorization"] = `Bearer ${parsedUser.authToken}`;

      setUserData(parsedUser);
      fetchAddresses();
      // console.log(parsedUser);
    } catch (error) {
      console.log(error);
      // console.log(error?.response?.data?.error);
      if (error?.response?.data?.error?.client_authentication) {
        alert("Invalid email or password. Please try again.");
      } else {
        if (error?.response?.data?.error) {
          alert(`${error.response?.data.error}`);
        } else {
          alert("Something unexpected happened. Please try again.");
        }
      }
    }
    setLoadingButtonogin(false);
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => signInGoogle(codeResponse),
    flow: "auth-code",
  });

  const fetchAddresses = async () => {
    try {
      const { data } = await api.get("/addresses");

      const parsedData = data.map((i) => ({
        id: i.id,
        ...i.attributes,
      }));
      setAddresses(parsedData);
    } catch (error) {
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(
              "An error has occurred.",
              `${error.response.data.errors[0].title}`
            );
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
    }
  };

  const signIn = async ({ email, password }) => {
    setLoading(true);

    try {
      const { data } = await api.post("/authenticate", {
        email: email.trim(),
        password,
      });

      const parsedUser = {
        id: data.id,
        ...data.attributes,
      };

      localStorage.setItem("@SettcorpClient: user", JSON.stringify(parsedUser));

      api.defaults.headers["Authorization"] = `Bearer ${parsedUser.authToken}`;

      setUserData(parsedUser);
      fetchAddresses();
      // console.log(parsedUser);
    } catch (error) {
      // console.log(error);
      // console.log(error?.response?.data?.error);
      if (error?.response?.data?.error?.client_authentication) {
        alert("Invalid email or password. Please try again.");
      } else {
        if (
          error?.response?.data?.errors &&
          error?.response?.data?.errors[0]?.title
        ) {
          alert(`${error.response.data.errors[0].title}`);
        } else {
          alert("Something unexpected happened. Please try again.");
        }
      }
    }
    setLoading(false);
  };

  const signOut = async () => {
    localStorage.removeItem("@SettcorpClient: user");
    api.defaults.headers["Authorization"] = undefined;

    setUserData(null);
  };

  useEffect(() => {
    const userStorage = localStorage.getItem("@SettcorpClient: user");

    if (userStorage) {
      const parsedUser = JSON.parse(userStorage);

      // Checking if the token has expired.
      if (parsedUser.expiresAt < new Date()) {
        signOut();
      } else {
        api.defaults.headers[
          "Authorization"
        ] = `Bearer ${parsedUser.authToken}`;
        setUserData(parsedUser);
      }
    } else {
      signOut();
    }
  }, []);

  const signOutIfTokenExpired = async () => {
    const user = await localStorage.getItem("@SettcorpClient: user");
    // fetchAddresses();
    if (user) {
      const parsedUser = JSON.parse(user);

      if (moment(parsedUser.expiresAt).isBefore(new Date())) {
        signOut();
      } else {
        api.defaults.headers[
          "Authorization"
        ] = `Bearer ${parsedUser.authToken}`;
        setUserData(parsedUser);
      }
    } else {
      signOut();
    }
  };

  useEffect(() => {
    signOutIfTokenExpired();
  }, []);

  return (
    <AppContext.Provider
      value={{
        signIn,
        signOut,
        user: userData,
        setLoading,
        loading,
        reloadPage,
        setRealoadPage,
        open,
        setOpen,
        loadingCoverPhoto,
        setLoadingCoverPhoto,
        reloadCard,
        setReloadCard,
        addresses,
        totalProperties,
        setTotalProperties,
        loginGoogle,
        loadingButtonogin,
        setLoadingButtonogin,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
