import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import Logo from "../../Assets/Group 807.svg";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import "./Login.css";
import api from "../../Service/api";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const form = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid email format.")
        .required("Email is required!"),
    }),

    onSubmit: (values) => handleContinue(values),
  });

  const handleContinue = async (values) => {
    try {
      setLoading(true);
      await api.post("forgot_password", { email: values.email });
      alert("Success, Check your email to create a new password.");
      navigate("/");
      setLoading(false);
    } catch (err) {
      //console.log(_error);
      if (err.response.status === 404) {
        alert("Error, This email is not associated with any account.");
      } else {
        alert("Error, Something happened, please try again.");
      }
    }
    setLoading(false);
  };

  return (
    <div className="containerBackgroundLogin">
      <div className="containerLogin">
        <img src={Logo} alt="logo" className="logoSett" />

        {/* <div className="containerLines">
        <div className="line"></div>
        <div className="text">or continue with email</div>
        <div className="line"></div>
      </div> */}

        <div className="containerInputs">
          <div className="errorINputRegi">
            <div
              style={{
                color: "#ffffff",
                marginBottom: "6px",
                fontSize: "13px",
                marginLeft: "5px",
              }}
            >
              E-mail
            </div>
            <input
              className="inputLogin"
              placeholder={"E-mail"}
              style={{ textIndent: "10px" }}
              type={"email"}
              onChange={(e) => {
                form.setFieldValue("email", e.target.value);
              }}
              onBlur={() => {
                form.setFieldTouched("email", true);
              }}
            />
            {form.touched.email && form.errors.email && (
              <Typography
                style={{ fontSize: 14, marginLeft: 5, marginTop: 5 }}
                color={"red"}
              >
                {form.errors.email}
              </Typography>
            )}
          </div>

          <div className="containerLoginForgot">
            <button
              type="submit"
              onClick={() => {
                form.handleSubmit();
              }}
              className="buttonLogin"
              style={{ fontSize: '20px'}}
            >
              {loading ? "Sending..." : "Send new password link"}
            </button>

            <a
              href="/"
              style={{
                color: "#ffffff",
                marginTop: "15px",
                fontSize: "15px",
                textDecoration: "none",
              }}
            >
              Back
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
