import React, { useContext, useState } from "react";
import api from '../../../Service/api';
import * as yup from "yup";
import { useFormik } from 'formik';
import { Input } from "../../../Components/Input";
import { InputsContainer } from "../../../Components/InputsContainer";
// import Add from '../../../Assets/add.svg';
import { useNavigate } from "react-router-dom";
// import MaskedInput from 'react-text-mask'
// import Loader from '../../../Components/Loader/index';
import AppContext from '../../../Context/AppContext';

import "./Payment.css";

const AddCredCard = ({selected}) => {
  let navigate = useNavigate();
  const { user, signOut } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  // const [pay, setPay] = useState([]);

  // console.log(pay);

  const form = useFormik({
    initialValues: {
      id: "",
      creditCardNumber: "",
      expirationDate: "",
      cvv: "",
      cardName: "",
    },
    validationSchema: yup.object({
      creditCardNumber: yup
        .string()
        .min(16, "Credit card number must be at least 16 digits")
        .max(16, "Credit card number must be at most 16 digits")
        .required(),
      expirationDate: yup
        .string()
        .min(4, "Invalid expiration date")
        .max(5, "Date be at most 4 digits")
        .required(),
      cvv: yup
        .string()
        .min(3, "CVV must be at least 3 digits")
        .max(3, "CVV must be at most 3 digits")
        .required(),
      cardName: yup.string().required("Credit card holder's name is a required field"),
    }),
    onSubmit: () => handleSubmit(),
  });

  // console.log(form.values);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      if (form.values.id) {
        await api.put(`/credit_cards/${form.values.id}`, {
          data: {
            attributes: {
              cardNumber: form.values.creditCardNumber,
              cardExpirationDate: form.values.expirationDate,
              cardCvv: form.values.cvv,
              cardName: form.values.cardName,
            },
          },
        });
        // signOut();
        navigate(1)
      } else {
        await api.post(`/credit_cards`, {
          data: {
            attributes: {
              cardNumber: form.values.creditCardNumber,
              cardExpirationDate: form.values.expirationDate.replace(/(\d{2})(\d{2})/, "$1/$2"),
              cardCvv: form.values.cvv,
              cardName: form.values.cardName,
            },
          },
        });
        // signOut();
        navigate(0)
      }

      alert("Update successfull!", "Please log in again.");
      //signOut();
    } catch (error) {
      if (error?.response?.status) {
        if (error.response.status !== 401) {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0]?.title
          ) {
            alert(
              `${error.response.data.errors[0].title}`
            );
          } else {
            alert("Something unexpected happened.", "Please try again.");
          }
        } else {
          alert("Session expired", "Please login in again.");
        }
      } else {
        alert("Something unexpected happened.", "Please try again.");
      }
    }

    setLoading(false);
  };

  return (
    <>
      <div style={{ maxWidth: 721 }}>
        {/* <button onClick={() => navigate("/PaymentHistory")} className="buttonPayment">
          PAYMENT HISTORY
        </button> */}

        <InputsContainer>
          <Input
            placeholder="Credit card holder name"
            field="cardName"
            form={form}
            assistiveText={{ color: "#0E1F41" }}
          />

          <Input
            placeholder="Card number"
            field="creditCardNumber"
            keyboardType="numeric"
            form={form}
            assistiveText={{ color: "#0E1F41" }}
            maxLength={16}
          />

          <Input
            placeholder="Expiration date"
            field="expirationDate"
            form={form}
            assistiveText={{ color: "#0E1F41" }}
            mask
            maxLength={5}
          />

          <Input
            placeholder="CVV"
            field="cvv"
            form={form}
            assistiveText={{ color: "#0E1F41" }}
            maxLength={3}
          />

        </InputsContainer>

        <button onClick={form.submitForm} className="buttonNewProperty">
          {loading ? "SAVING..." : "SAVE"}
        </button>

        {/* <button onClick={() => selected(true)} className="buttonNewProperty">
          {loading ? "SAVING..." : "SAVE"}
        </button> */}
      </div>
    </>
  );
};

export default AddCredCard;
