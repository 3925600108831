import React, { useContext } from 'react';
import AppContext from '../Context/AppContext';
import Box from '@mui/material/Box';
import { Button } from '../Components/Button';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const BackdropUnstyled = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'MuiBackdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

BackdropUnstyled.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: "auto",
  bgcolor: 'background.paper',
  borderRadius: 2,
  // border: '2px solid #000',
  boxShadow: 0,
  p: 0,
};

export function ComponentModal({ children, disableButton, text }) {
  const { open, setOpen } = useContext(AppContext);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {disableButton ? (
        <button
          className='listOfTasks'
          onClick={handleOpen}
        >
          {text}
        </button>
      ) : (
        <Button
          text={text ? text : "Review"}
          edit style={{ height: 30, width: "100%", marginRight: 10, display: "flex" }}
          onClick={handleOpen}
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          {children}
        </Box>
      </Modal>
    </div>
  );
}
