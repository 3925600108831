import React, { useContext, useState } from "react";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import Done from '../../Assets/Group 1068.svg';
import { useNavigate } from "react-router-dom";

import "./NewProperty.css";

const AllDone = () => {
  let navigate = useNavigate();

  return (
    <AuthorizedLayout>
      <div className="containerAllDone">
        <img src={Done} alt="done" />
        <div className="title">All Done!</div>
        <div className="subtitle">You can follow your schedules at the calendar!</div>
        <button onClick={() => navigate("/Schedules")}>Go back to the calendar</button>
      </div>
    </AuthorizedLayout>
  );
};

export default AllDone;