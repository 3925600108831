import React, { useState, useEffect, useContext } from "react";
import api from '../../Service/api';
import AppContext from "../../Context/AppContext";
import { AuthorizedLayout } from '../../Components/AuthorizedLayout';
import { Divider } from '@mui/material';
import ReviewCard from './Components/ReviewCard';

import "./Reviews.css";

const Reviews = () => {
  const { reloadPage, setRealoadPage } = useContext(AppContext);
  const [selected, setSelected] = useState(true);
  const [schedulesToReview, setSchedulesToReview] = useState([]);
  const [reviewed, setReviewed] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [activeScreen, setActiveScreen] = useState("NEED");

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true);
      try {
        const { data: reviewsData } = await api.get("/schedules/without_review");
  
        if (reviewsData && reviewsData.length > 0) {
          setSchedulesToReview(
            reviewsData.map((e) => ({ id: e.id, ...e.attributes }))
          );
        } else {
          setSchedulesToReview([]);
        }
      } catch (error) {
        //console.log(_error);
        if (error?.response?.status) {
          if (error.response.status !== 401) {
            if (
              error?.response?.data?.errors &&
              error?.response?.data?.errors[0]?.title
            ) {
              alert(
                `${error.response.data.errors[0].title}`
              );
            } else {
              alert("Something unexpected happened.", "Please try again.");
            }
          } else {
            alert("Session expired", "Please login in again.");
          }
        } else {
          alert("Something unexpected happened.", "Please try again.");
        }
      }
      setLoading(false);
    };
    fetchReviews();
    setRealoadPage(false);
  }, [reloadPage, setRealoadPage]);

  // console.log(reviewed);

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true);
      try {
        const { data: reviewsData } = await api.get("/schedules/with_review");
  
        if (reviewsData && reviewsData.length > 0) {
          setReviewed(reviewsData.map((e) => ({ id: e.id, ...e.attributes })));
        } else {
          setReviewed([]);
        }
      } catch (error) {
        //console.log(_error);
        if (error?.response?.status) {
          if (error.response.status !== 401) {
            if (
              error?.response?.data?.errors &&
              error?.response?.data?.errors[0]?.title
            ) {
              alert(
                `${error.response.data.errors[0].title}`
              );
            } else {
              alert("Something unexpected happened.", "Please try again.");
            }
          } else {
            alert("Session expired", "Please login in again.");
          }
        } else {
          alert("Something unexpected happened.", "Please try again.");
        }
      }
      setLoading(false);
    };
    fetchReviews();
    setRealoadPage(false);
  }, [reloadPage, setRealoadPage]);

  return (
    <AuthorizedLayout>
      <p className="titlePage">Review your last cleanings</p>
      <p className="subtitlePage" style={{ width: "320px" }}>
        Here you can access you last cleanings and help us keep the quality of
        the cleaning.
      </p>
      <div className="conatainerButtons">
        <button
          className={
            selected ? "buttonReviews buttonReviewsSelected" : "buttonReviews"
          }
          onClick={() => setSelected(true)}
        >
          NEED REVIEW
        </button>
        <button
          className={
            !selected ? "buttonReviews buttonReviewsSelected" : "buttonReviews"
          }
          onClick={() => setSelected(false)}
        >
          REVIEWED
        </button>
      </div>
      <Divider style={{ marginTop: 15, marginBottom: 15,  maxWidth: 721 }}/>
      {selected ? (
          <>
            <div className={schedulesToReview.length > 0 ? "containerAllCards" : ""}>
              {schedulesToReview.length > 0 &&
                schedulesToReview.map((s, i) => (
                  <ReviewCard schedule={s} key={i} star={selected} />
                ))}
            </div>
            {schedulesToReview.length === 0 && (
              <p style={{ marginBottom: 50 }}>
                You don't have any pending reviews
              </p>
            )}
          </>
        ) : (
          <>
            <div className={reviewed.length > 0 ? "containerAllCards" : ""}>
              {reviewed.length > 0 &&
                reviewed.map((s, i) => (
                  <ReviewCard schedule={s} key={i} star={selected} />
                ))}
            </div>
            {reviewed.length === 0 && (
              <p style={{ marginBottom: "60px"}}>
                You don't have any reviewed cleaning
              </p>
            )}
          </>
        )}
    </AuthorizedLayout>
  );
};

export default Reviews;
