import React from "react";

import Illustration1 from '../../../../../Assets/Illustration1.svg';

import './Screen.css';

const Screen1 = () => {
  return (
    <div className="containerCardScren">
      <div className="titleTextOnboard">Scheduled cleaning calendar onboarding</div>
      <img src={Illustration1} alt="dsa" className="img"/>
    </div>
  );
}

export default Screen1;
