import React, { useContext, useState } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Input } from "../../Components/Input";

import "./GetIn.css";

const GetIn = ({ form, onClick, loading }) => {
  // console.log(form.errors.instructions);
  // console.log(form.values);

  const verifyCheck = (name) => (form.values.way === name ? true : false);

  const [checked, setChecked] = useState([
    { value: "someone is home", checked: verifyCheck("someone_is_home") },
    { value: "doorcode", checked: verifyCheck("doorcode") },
    { value: "hidden key", checked: verifyCheck("hidden_key") },
    { value: "other", checked: verifyCheck("other") },
  ]);

  const toggleCheckbox = (value, index) => {
    const checkboxData = [...checked];

    for (let i = 0; i < checked.length; i += 1) {
      checked[i].checked = false;
    }

    checked[index].checked = true;
    setChecked(checkboxData);
    form.setFieldValue("way", value.value.replace(/\s/g, "_"));
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <FormGroup style={{ marginTop: 20, marginBottom: 10 }}>
          {checked.map((value, idx) => (
            <FormControlLabel
              key={idx}
              control={
                <Checkbox
                  checked={value.checked}
                  onChange={() => toggleCheckbox(value, idx)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={value.value}
            />
          ))}
        </FormGroup>

        {verifyCheck("someone_is_home") ? null : (
          <div style={{ maxWidth: "721px" }}>
            <Input
              placeholder="instructions"
              field="instructions"
              form={form}
              assistiveText={{ color: "#0E1F41" }}
            />
          </div>
        )}

        <button
          onClick={form.submitForm}
          className="buttonNewProperty"
          style={{ maxWidth: "721px", marginTop: 0 }}
        >
          {loading ? "SAVING..." : "SAVE"}
        </button>
      </div>
    </>
  );
};

export default GetIn;
