import React from "react";
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import './PropertyCard.css';

const LoadingCards = () => {

  return (
    <Card
      sx={{ width: 400, borderRadius: 3, height: 356 }}
      className="seletor"
    >
      <Stack spacing={3}>
        <Skeleton variant="rectangular" width={400} height={170} />
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
          <Skeleton variant="rectangular" width={340} height={80} />
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
            <Skeleton variant="rectangular" width={100} height={30} />
            <Skeleton variant="rectangular" width={100} height={30} />
            <Skeleton variant="rectangular" width={100} height={30} />
          </div>
        </div>
      </Stack>
    </Card>
  );
};

export default LoadingCards;
