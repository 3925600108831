import React, { useState, useContext, useEffect } from "react";
import { AuthorizedLayout } from "../../Components/AuthorizedLayout";
import DatePicker from "react-datepicker";
import AppContext from "../../Context/AppContext";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import api from "../../Service/api";
import Calendar from "./components/Calendar";
import CalendarCard from "./components/CalendarCard";
import Onboard from "./components/Onboard";
import CalendarImage from "../../Assets/Group 1062.svg";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

import "./Schedules.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Schedules = () => {
  const { user, reloadCard, setReloadCard } = useContext(AppContext);
  const [reviwed, setReviwed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [schedules, setSchedules] = useState({});
  const [selectedDaySchedules, setSelectedDaySchedules] = useState([]);
  const [allSchedules, setAllSchedules] = useState([]);
  const [scdulesDefined, setScdulesDefined] = useState(
    dayjs("2014-08-18T21:11:54")
  );
  const [regions, setRegions] = useState([]);
  const [properties, setProperties] = useState([]);
  const [nameRegion, setNameRegion] = useState("All regions");
  const [nameProperty, setNameProperty] = useState("All properties");

  useEffect(() => {
    const getRegions = async () => {
      try {
        const { data } = await api.get(`https://api.sett.solutions/cities`);

        const values = data.filter(function (a) {
          return (
            !this[JSON.stringify(a.attributes?.region?.name)] &&
            (this[JSON.stringify(a.attributes?.region?.name)] = true)
          );
        }, Object.create(null));
        setRegions(values);
      } catch (error) {
        // console.log(error);
      }
    };

    getRegions();
  }, []);

  useEffect(() => {
    if (schedules && allSchedules?.length > 0) {
      setSelectedDaySchedules(
        allSchedules.filter(
          (item) =>
            item.attributes.date === moment(selectedDay).format("YYYY-MM-DD")
        )
      );
    }
    setReloadCard(false);
  }, [selectedDay, schedules, allSchedules, reloadCard]);

  const scheduleParsing = (sched, day) => {
    if (!day) {
      const scheduleDates = sched.map((s) => s.attributes.date);

      const parsedSchedules = scheduleDates.reduce((acc, date) => {
        return {
          ...acc,
          [date]: {
            marked: true,
            dotColor: "red",
            selected: date === selectedDay ? true : false,
          },
        };
      }, {});
      setSchedules(parsedSchedules);
    } else {
      const scheduleDates = sched.map((s) => s.attributes.date);

      const parsedSchedules = scheduleDates.reduce((acc, date) => {
        return {
          ...acc,
          [date]: {
            marked: true,
            dotColor: "red",
            selected: date === selectedDay ? true : false,
          },
        };
      }, {});

      setSchedules({
        ...parsedSchedules,
        [day]: {
          marked: false,
          dotColor: "red",
          selected: day === selectedDay ? true : false,
        },
      });
    }
  };

  // const fetchSchedules = async () => {
  //   setLoading(true);
  //   try {
  //     const { data: pastSchedules } = await api.get("/schedules/past");
  //     const { data: nextSchedules } = await api.get("/schedules/next");

  //     const temp = [...pastSchedules, ...nextSchedules];

  //     setAllSchedules(temp);
  //     setScdulesDefined(temp);
  //     setProperties(temp);

  //     if (temp.find((item) => item.attributes.date === selectedDay)) {
  //       scheduleParsing(temp);
  //     } else {
  //       scheduleParsing(temp, selectedDay);
  //     }

  //     setLoading(false);
  //   } catch (error) {
  //     //console.log(_error);
  //     if (error?.response?.status) {
  //       if (error.response.status !== 401) {
  //         if (
  //           error?.response?.data?.errors &&
  //           error?.response?.data?.errors[0]?.title
  //         ) {
  //           alert(`${error.response.data.errors[0].title}`);
  //         } else {
  //           alert("Something unexpected happened.", "Please try again.");
  //         }
  //       } else {
  //         alert("Session expired", "Please login in again.");
  //       }
  //     } else {
  //       alert("Something unexpected happened.", "Please try again.");
  //     }
  //   }
  //   setLoading(false);
  // };

  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [selectedYear, setSelectedYear] = useState(moment().year());

  const fetchSchedules = async (month, year) => {
    setLoading(true);
    setAllSchedules([]);
    setSelectedDaySchedules([]);
    try {
      const response = await api.get(`/schedules/all`, {
        params: {
          month: Number(month) + 1 || selectedMonth + 1,
          year: year || selectedYear,
        },
      });

      setLoading(false);
      setAllSchedules(response?.data);
      setScdulesDefined(response?.data);
      setProperties(response?.data);
    } catch (erro) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSchedules();
    setReloadCard(false);
  }, [reloadCard]);

  useEffect(() => {
    if (loading === false && selectedDay) {
      if (Object.keys(schedules).find((item) => item === selectedDay)) {
        scheduleParsing(allSchedules);
      } else {
        scheduleParsing(allSchedules, selectedDay);
      }
    }
  }, [selectedDay]);

  const filterRegions = (name) => {
    setNameRegion(name);
    setSelectedDaySchedules([]);
    setNameProperty("");

    if (name === "All") {
      setAllSchedules(scdulesDefined);
      setProperties(scdulesDefined);
    } else {
      const filtered = scdulesDefined.filter(
        (value) => value?.attributes?.address?.region === name
      );
      setAllSchedules(filtered);
      setProperties(filtered);
    }
  };

  const filterProperty = (name) => {
    setNameProperty(name);
    setSelectedDaySchedules([]);

    if (name === "All") {
      setAllSchedules(properties);
    } else {
      const filtered = scdulesDefined.filter(
        (value) => value?.attributes?.address?.address_nickname === name
      );
      setAllSchedules(filtered);
    }
  };

  const goBack = () => {
    fetchSchedules();
  };

  return (
    <AuthorizedLayout>
      <Tooltip title="How Calendar Works">
        <button className="onOnboradingButton" onClick={() => setReviwed(true)}>
          ?
        </button>
      </Tooltip>
      <Onboard setReviwed={setReviwed} open={reviwed} />
      <div className="containerSchu">
        <div className="containerFIlterCalendar">
          <div className="conatinerRegion">
            <div className="titleFilter">Regions</div>
            <Box>
              <FormControl>
                <Select
                  labelId="demo"
                  disabled={scdulesDefined.length > 0 ? false : true}
                  value={nameRegion}
                  label="Name"
                  onChange={({ target }) => filterRegions(target.value)}
                  className="inputProperty"
                  sx={{
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                  displayEmpty
                >
                  <MenuItem value="All">All regions</MenuItem>
                  {regions.map((value, idx) => (
                    <MenuItem
                      key={idx}
                      value={
                        value?.attributes?.region?.name || "Deleted Property"
                      }
                    >{`${
                      value?.attributes?.region?.name || "Deleted Property"
                    }`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="conatinerProperty">
            <div className="titleFilter">Properties</div>
            <Box>
              <FormControl>
                <Select
                  labelId="demo"
                  disabled={scdulesDefined.length > 0 ? false : true}
                  value={nameProperty}
                  label="Name"
                  onChange={({ target }) => filterProperty(target.value)}
                  className="inputProperty"
                  sx={{
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                  displayEmpty
                >
                  <MenuItem value="All">
                    {allSchedules.length > 0
                      ? "All properties"
                      : "No registered property"}
                  </MenuItem>
                  {properties.length > 0
                    ? properties.map((value, idx) => (
                        <MenuItem
                          key={idx}
                          value={
                            value?.attributes?.address?.address_nickname ||
                            "Deleted Property"
                          }
                        >{`${
                          value?.attributes?.address?.address_nickname ||
                          "Deleted Property"
                        }`}</MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        <div className="containerCalendar">
          {/* <DatePicker
            selected={selectedDay}
            onChange={setSelectedDay}
            inline
            dayClassName={(date) => (checkDate(date) ? "random" : undefined)}
            className="red-border"
          /> */}

          <Calendar
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            allSchedules={allSchedules}
            loading={loading}
            onChange={(month, year) => {
              setSelectedMonth(Number(month));
              setSelectedYear(year);
              fetchSchedules(Number(month), year);
            }}
          />

          <div className="containerCardsCalendar">
            {selectedDaySchedules.length > 0 && loading === false ? (
              <div className="textDate">{`${moment(selectedDay).format(
                "MM/DD/YYYY"
              )}`}</div>
            ) : null}
            <div className="cards">
              {selectedDaySchedules.length > 0 && loading === false ? (
                selectedDaySchedules.map((sched, key) => (
                  <CalendarCard schedule={sched} key={key} goBack={goBack} />
                ))
              ) : (
                <div className="backgroudNoDate">
                  <img src={CalendarImage} alt="React Logo" />
                  <div>Select a day to list the cleanings</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Schedules;
